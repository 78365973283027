import { useEffect } from "react";
import { type MessageDescriptor, useIntl } from "react-intl";

export function useDocumentTitle(title: string): void {
  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);
}

export function setDocumentTitle(title: string): void {
  document.title = `${WEBPACK_LABEL_TITLE} | ${title}`;
}

export function useIntlDocumentTitle(intlId: MessageDescriptor["id"]) {
  useDocumentTitle(useIntl().formatMessage({ id: intlId }));
}
