import "react-datepicker/dist/react-datepicker.css";
import "../../../assets/css/react-datepicker.css";

import moment, { type Moment } from "moment";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { addInstructions, removeInstructions } from "../../../actions/patient_new_instructions";
import { Gender, Payer } from "../../../common/constants";
import { isChildrenCourse, isTeenCourse, Status } from "../../../common/courses";
import { DATE_FORMAT, formatDatetime } from "../../../common/datetime";
import remoteLog from "../../../common/logging";
import { isPatient } from "../../../common/patient";
import { deployedRussia } from "../../../common/utils";
import type { TPatient } from "../../../reducers/dashboard";
import type { RootState } from "../../../store";
import Loader from "../../common/loadingInProgress";
import { QuestionTooltip } from "../../ui/tooltip";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    lang: state.intl,
    instructions: state.instructions,
  };
};

const mapDispatchToProps = {
  addInstruction: addInstructions,
  removeInstruction: removeInstructions,
};

type PatientUpdatePatientInfoProps = PropsFromRedux & { intl: IntlShape } & {
  setDirty(key: string, value: string | number | null): void;
};

type PatientUpdatePatientInfoState = {
  startDate: Moment | null;
};

class PatientUpdatePatientInfo extends Component<
  PatientUpdatePatientInfoProps,
  PatientUpdatePatientInfoState
> {
  constructor(props: PatientUpdatePatientInfoProps) {
    super(props);
    this.state = {
      startDate: null,
    };
    this.addFirstName = this.addFirstName.bind(this);
    this.addLastName = this.addLastName.bind(this);
    this.addMiddleName = this.addMiddleName.bind(this);
    this.selectSex = this.selectSex.bind(this);
    this.addBdate = this.addBdate.bind(this);
    this.addEmail = this.addEmail.bind(this);
    this.renderPayerFields = this.renderPayerFields.bind(this);
    this.addPayerFirstName = this.addPayerFirstName.bind(this);
    this.addPayerLastName = this.addPayerLastName.bind(this);
    this.addPayerMiddleName = this.addPayerMiddleName.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_update_patient_info");
  }

  componentDidMount() {
    const { patient } = this.props;
    if (isPatient(patient)) {
      this.addFirstName(patient.first_name);
      this.addLastName(patient.last_name);
      this.addMiddleName(patient.middle_name.toLowerCase() === "none" ? null : patient.middle_name);
      this.selectSex(patient.sex);
      if (patient.bdate && patient.bdate.toLowerCase() !== "none") {
        this.addBdate(moment(patient.bdate));
      }
      if (deployedRussia() && this.props.intl.locale == "ru") {
        this.addEmail(patient.email ? patient.email : null);
        this.addPayerFirstName(patient.payer_first_name ? patient.payer_first_name : null);
        this.addPayerLastName(patient.payer_last_name ? patient.payer_last_name : null);
        this.addPayerMiddleName(patient.payer_patronymic ? patient.payer_patronymic : null);
      }
    }
  }

  addFirstName(data: string) {
    this.props.addInstruction({ first_name: data });
    this.props.setDirty("first_name", data);
  }

  addLastName(data: string) {
    this.props.addInstruction({ last_name: data });
    this.props.setDirty("last_name", data);
  }

  addMiddleName(data: string | null) {
    this.props.addInstruction({ middle_name: data });
    this.props.setDirty("middle_name", data);
  }

  addPayerFirstName(data: string | null) {
    this.props.addInstruction({ payer_first_name: data });
    this.props.setDirty("payer_first_name", data);
  }

  addPayerLastName(data: string | null) {
    this.props.addInstruction({ payer_last_name: data });
    this.props.setDirty("payer_last_name", data);
  }

  addPayerMiddleName(data: string | null) {
    this.props.addInstruction({ payer_patronymic: data });
    this.props.setDirty("payer_patronymic", data);
  }

  selectSex(data: TPatient["sex"] | string | null) {
    if (data == Gender.FEMALE || data == Gender.MALE) {
      this.props.addInstruction({ sex: parseInt(data) });
      this.props.setDirty("sex", parseInt(data));
    }
  }

  addBdate(data: Moment) {
    if (data) {
      this.setState({ startDate: data });
      this.props.addInstruction({ bdate: moment(data).format("YYYY-MM-DD") });
      this.props.setDirty("bdate", moment(data).format("YYYY-MM-DD"));
    }
  }

  addEmail(data: string | null) {
    this.props.addInstruction({ email: data });
    this.props.setDirty("email", data);
  }

  renderPayerFields() {
    const locale = this.props.intl.locale;
    const { patient, instructions } = this.props;
    const fm = (id: MessageDescriptor["id"]) => this.props.intl.formatMessage({ id });

    const isPayerNameRequired = instructions.course_id
      ? isChildrenCourse(instructions.course_id) || isTeenCourse(instructions.course_id)
      : false;

    if (
      deployedRussia() &&
      locale == "ru" &&
      instructions &&
      instructions.payer_id == Payer.PATIENT
    ) {
      if (patient.status == Status.UNFILLED_CASE) {
        return (
          <div className="form-group">
            <label
              className="control-label"
              style={{ fontWeight: "900" }}
              htmlFor="email"
              id="validation-email"
            >
              <FormattedMessage id="PAT_EMAIL" />
              <span className="required" aria-required="true">
                *
              </span>
            </label>
            <input
              data-matomo-mask
              type="text"
              className="form-control"
              id="email"
              name="email"
              placeholder={fm("PAT_INFO_EMAIL_PLACEHOLDER")}
              onChange={(e) => this.addEmail(e.target.value)}
              value={instructions && instructions.email ? instructions.email : ""}
            />
            <br />
            <div className="row">
              <label
                className="control-label col-md-8"
                id="payer-another-person-label"
                style={{ fontWeight: "600" }}
              >
                <FormattedMessage id="another.payer.person" />
              </label>
            </div>
            <div className="row" id="patient-block-body">
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="control-label"
                    id="payer-lastname-label"
                    style={{ fontWeight: "600" }}
                    htmlFor="payer-lastname-value"
                  >
                    <FormattedMessage id="PAYER_LAST_NAME" />
                  </label>
                  {isPayerNameRequired ? (
                    <span className="required" aria-required="true">
                      *
                    </span>
                  ) : null}
                  <input
                    data-matomo-mask
                    type="text"
                    className="form-control"
                    id="payer-lastname-value"
                    name="payer_last_name"
                    placeholder={fm("PAYER_LAST_NAME_PLACEHOLDER")}
                    onChange={(e) => this.addPayerLastName(e.target.value)}
                    value={
                      instructions && instructions.payer_last_name
                        ? instructions.payer_last_name
                        : ""
                    }
                    maxLength={64}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="control-label"
                    id="payer-firstname-label"
                    style={{ fontWeight: "600" }}
                    htmlFor="payer-firstname-value"
                  >
                    <FormattedMessage id="PAYER_FIRST_NAME" />
                  </label>
                  {isPayerNameRequired ? (
                    <span className="required" aria-required="true">
                      *
                    </span>
                  ) : null}
                  <input
                    data-matomo-mask
                    type="text"
                    className="form-control"
                    id="payer-firstname-value"
                    name="payer_first_name"
                    placeholder={fm("PAYER_FIRST_NAME_PLACEHOLDER")}
                    onChange={(e) => this.addPayerFirstName(e.target.value)}
                    value={
                      instructions && instructions.payer_first_name
                        ? instructions.payer_first_name
                        : ""
                    }
                    maxLength={64}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="control-label"
                    id="payer-middlename-label"
                    style={{ fontWeight: "600" }}
                    htmlFor="payer-middlename-value"
                  >
                    <FormattedMessage id="PAYER_MIDDLE_NAME" />
                  </label>
                  {isPayerNameRequired ? (
                    <span className="required" aria-required="true">
                      *
                    </span>
                  ) : null}
                  <input
                    data-matomo-mask
                    type="text"
                    className="form-control"
                    id="payer-middlename-value"
                    name="payer_patronymic"
                    placeholder={fm("PAYER_MIDDLE_NAME_PLACEHOLDER")}
                    onChange={(e) => this.addPayerMiddleName(e.target.value)}
                    value={
                      instructions && instructions.payer_patronymic
                        ? instructions.payer_patronymic
                        : ""
                    }
                    maxLength={64}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ marginBottom: "20px" }}>
            <label className="control-label" id="validation-email" style={{ fontWeight: "900" }}>
              <FormattedMessage id="PAT_EMAIL" />
              <br />
              <span data-matomo-mask data-hj-suppress style={{ fontWeight: "400" }}>
                {patient.email}
              </span>
            </label>
          </div>
        );
      }
    }
    return null;
  }

  render() {
    if (isPatient(this.props.patient)) {
      const fm = (id: MessageDescriptor["id"]) => this.props.intl.formatMessage({ id });
      const locale = this.props.lang.locale;
      const patient = this.props.patient;
      const old_instructions = this.props.patient.instructions;
      if (old_instructions) {
        Object.keys(old_instructions).map((x) =>
          old_instructions[x] == "None" || old_instructions[x] == "NA"
            ? (old_instructions[x] = "")
            : null,
        );
      }

      const isNameReadOnly = this.props.patient.status != Status.UNFILLED_CASE;

      return (
        <div>
          <h3 className="block" id="patient-block-label" style={{ fontWeight: "900" }}>
            <FormattedMessage id="BLOCKHEAD_PAT_INFO" />
          </h3>
          <div className="row" id="patient-block-body">
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="control-label"
                  id="patient-lastname-label"
                  style={{ fontWeight: "900" }}
                  htmlFor="patient-lastname-value"
                >
                  <FormattedMessage id="PAT_INFO_LAST_NAME" />
                  <span className="required" aria-required="true">
                    *
                  </span>
                  {isNameReadOnly ? (
                    <QuestionTooltip>
                      <FormattedMessage id="PAT_INFO_NAME_TOOLTIP" />
                    </QuestionTooltip>
                  ) : null}
                </label>
                <input
                  id="patient-lastname-value"
                  data-matomo-mask
                  type="text"
                  className="form-control"
                  style={{
                    cursor: isNameReadOnly ? "not-allowed" : undefined,
                  }}
                  name="last_name"
                  placeholder={fm("PAT_INFO_LAST_NAME_PLACEHOLDER")}
                  onChange={isNameReadOnly ? undefined : (e) => this.addLastName(e.target.value)}
                  defaultValue={patient.last_name}
                  readOnly={isNameReadOnly}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="control-label"
                  id="patient-firstname-label"
                  style={{ fontWeight: "900" }}
                  htmlFor="patient-firstname-value"
                >
                  <FormattedMessage id="PAT_INFO_FIRST_NAME" />
                  <span className="required" aria-required="true">
                    *
                  </span>
                </label>
                <input
                  id="patient-firstname-value"
                  data-matomo-mask
                  type="text"
                  className="form-control"
                  style={{
                    cursor: isNameReadOnly ? "not-allowed" : undefined,
                  }}
                  name="first_name"
                  placeholder={fm("PAT_INFO_FIRST_NAME_PLACEHOLDER")}
                  onChange={isNameReadOnly ? (e) => this.addFirstName(e.target.value) : undefined}
                  defaultValue={patient.first_name}
                  readOnly={isNameReadOnly}
                />
              </div>
            </div>
            {deployedRussia() ? (
              <div className="col-md-4">
                <div className="form-group">
                  <label
                    className="control-label"
                    id="patient-middlename-label"
                    style={{ fontWeight: "900" }}
                    htmlFor="patient-middlename-value"
                  >
                    <FormattedMessage id="PAT_INFO_MIDDLE_NAME" />
                    <span className="required"></span>
                  </label>
                  <input
                    id="patient-middlename-value"
                    data-matomo-mask
                    type="text"
                    className="form-control"
                    style={{
                      cursor: isNameReadOnly ? "not-allowed" : undefined,
                    }}
                    name="patronymic_name"
                    placeholder={fm("PAT_INFO_MIDDLE_NAME_PLACEHOLDER")}
                    onChange={
                      isNameReadOnly ? (e) => this.addMiddleName(e.target.value) : undefined
                    }
                    defaultValue={
                      patient.middle_name == "none" || patient.middle_name == "None"
                        ? null
                        : patient.middle_name
                    }
                    readOnly={isNameReadOnly}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {this.renderPayerFields()}
          <div className="form-group">
            <label
              className="control-label"
              id="patient-gender-label"
              style={{ fontWeight: "900" }}
            >
              <FormattedMessage id="HEADER_SEX" />
            </label>
            <div className="radio-list">
              <label>
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="patient-gender-value-female"
                      name="sex"
                      defaultValue={Gender.FEMALE}
                      onChange={(e) => this.selectSex(e.target.value)}
                      defaultChecked={patient.sex == Gender.FEMALE}
                    />
                  </span>
                </div>{" "}
                <FormattedMessage id="SEX_F" />{" "}
              </label>
              <label>
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="patient-gender-value-male"
                      name="sex"
                      defaultValue={Gender.MALE}
                      onChange={(e) => this.selectSex(e.target.value)}
                      defaultChecked={patient.sex == Gender.MALE}
                    />
                  </span>
                </div>{" "}
                <FormattedMessage id="SEX_M" />{" "}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label
                  className="control-label"
                  id="patient-bdate-label"
                  style={{ fontWeight: "900" }}
                  htmlFor="patient-bdate-value"
                >
                  <FormattedMessage id="HEADER_BDATE" />
                </label>
                <div>
                  <DatePicker
                    locale={locale}
                    className="form-control"
                    id="patient-bdate-value"
                    dateFormat={DATE_FORMAT}
                    minDate={moment("01-01-1900", DATE_FORMAT)}
                    openToDate={moment("01-01-1985", DATE_FORMAT)}
                    selected={this.state.startDate}
                    onSelect={(e) => this.addBdate(e)}
                    onChangeRaw={(e) => formatDatetime(e)}
                    onBlur={(e) => {
                      if (e.target.value.length < 10) {
                        e.target.value = "";
                        this.props.removeInstruction({ bdate: null });
                      }
                    }}
                    placeholderText={DATE_FORMAT}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <Loader />;
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientUpdatePatientInfo));
