const messages = {
  // General
  "not.selected"                      : "Not selected",
  "None"                              : "NA",
  "None_USA"                          : "NA",
  "None_V2"                           : "NA",
  "NA"                                : "-",
  "table.search"                      : "Search",
  "table.status"                      : "Filter by status",
  "table.clear"                       : "Clear",
  "table.from"                        : "From",
  "table.to"                          : "To",
  "button.all"                        :  "All",
  "button.save_changes"               : "Save changes",
  "general.payment.show"              : "Show payments",
  "general.payment.close"             : "Hide payments",
  "general.retry"                     : "Retry",
  "bool.true"                         : "Yes",
  "bool.false"                        : "No",
  "word.and"                          : "and",
  "select.option"                     : "Select",
  "date"                              : "Date",
  "millimetre"                        : "mm",
  "requiredField"                     : "Required fields",
  "doctor.fullname"                   : "Full name",
  "doctor.profile.info"               : "Profile information",
  "not.found"                         : "Not found",
  "click.to.copy"                     : "Click to copy",
  "next"                              : "Next",
  "previous"                          : "Previous",
  "cancel"                            : "Cancel",
  "fifty"                             : "50",
  "hundred"                           : "100",
  "two.hundred"                       : "200",
  "here"                              : "here",
  "page"                              : " page",
  "loading"                           : "Loading...",
  "search"                            : "Search",
  "search.clear"                      : "Clear search",
  "error_boundary.title"              : "Error",
  "error_boundary.message"            : "Something went wrong",
  "error_boundary.message_2"          : "Try reloading the page or coming back later",
  "scroll_to_top"                     : "Scroll to top",
  "profile_menu"                      : "Profile menu",
  "alert.close"                       : "Close",
  "telegram_id.required"              : "This field is required when subscribing to Telegram notifications",

  "E_CONNECTION_FAILED"               : "Network connection error",

  // login page
  "login.header"                      : "Doctor Account",
  "login.login"                       : "Login",
  "login.password"                    : "Password",
  "login.show_password"               : "Show password",
  "login.enter"                       : "Login",
  "login.forgot"                      : "Forgot your password ?",
  "login.forgot_cta"                  : "Forgotten password?",
  "E_INVALID_LOGIN"                   : "Invalid credentials",
  // password reminder
  "reminder.forgot": "Restore password",
  "reminder.email":
    "Please enter your login or email address to restore password",
  "reminder.placeholder": "Email",
  "reminder.placeholder.login": "Login or Email",
  "reminder.back": "Back",
  "reminder.submit": "Send",
  "reminder.mail_send": "One-time link has been sent to your email",
  "reminder.link": "Go to login page.",
  "reminder.payment":
    "This is a friendly reminder that you have an open balance requiring your attention!",
  "reminder.payment.card":
    "If you have set up credit card payment, you can easily clear the balance by visiting the",
  "reminder.payment.invoice":
    "If you prefer to pay via invoice, please request it",
  "reminder.my.payments": '"My Payments"',

  "restore_password.success": "New password has been set. ",
  "restore_password.success_link": "Go to login page.",
  "restore_password.fail":
    "The link to restore your password has expired. Please, restart the procedure to restore your password. ",
  "restore_password.fail_link": "One-time link has been sent to your email",
  //tooltips
  "tooltip_shipment"                  : "Here you’re able to track the aligners' delivery",
  "tooltip_payment"                   : "You can always change the payment method of your patient if you go to Patients-Edit (at the end of the page) and then select the payment method",
  "tooltip_notifications"             : "Here you receive all notifications",
  "tooltip_3d_plan_approval_date"     : "Your aligners will be delivered within 15 business days after this date",
  "tooltip_precise_first_comment"     : "First comment from 3D Precise - {labelTitle}  ",
  "tooltip_precise_last_comment"      : "Latest comment from 3D Precise - {labelTitle}",
  "required.fields.tooltip"           : "All fields marked with an asterisk are mandatory as the more information we receive on your treatment goals, the more accurate 3D plan we will be able create. Regardless, if you don’t feel comfortable completing all non-mandatory fields, please know that our advanced AI will provide you with the best possible 3D plan based on Andrew's six keys of occlusion and anatomical boundaries.",
  "course.type.tooltip"               : "Case type is not only determined by length of treatment, but by movement type. Please refer to this ",
  "course.type.tooltip.sec"           : "If after the case has been submitted the case type needs to be adjusted, we will communicate it promptly to you.",
  "tooltip.chart"                     : "chart",
  "upload.photos.tooltip"             : "High-quality pictures are crucial to treatment plan accuracy. Please see the ",
  "upload.photos.manual.tooltip"      : "manual",
  "cbct.upload.tooltip"               : "Click to see the video instruction how to upload CBCT files",
  "elastics.tooltip"                  : "Optional for most movements. If you leave this field blank, our technician will inform you if they are needed upon creating the 3D plan",
  "tooltip.correction.btn.title"      : "The following conditions must be met in order to submit a correction: ",
  "tooltip.correction.btn.payment.status": "1.You must pay for the initial case.",
  "tooltip.correction.btn.warranty.date" : "2.The number of refinements has not exceeded the allowed amount. ",
  "tooltip.correction.btn.error"      : "If these conditions have been met and you are unable to submit a refinement, please call ",
  "tooltip.corrcetion.btn.support"    : "To order a refinement, please reach out to support (the chat on the bottom right) to upgrade the case to Predict Full and pay the difference",
  "tooltip.correction.btn.no.course"  : "Please submit a patient to be able to order correction",
  "tooltip.retainers"                 : "You are not able to order retainers if your case type is “Retainers”. If you want to change it, please call ",
  "tooltip.support"                   : "3D Predict Support",
  "tooltip.click.approve"             : "Сlick to approve",

  //tooltips
  // tooltip_shipment: "Here you’re able to track the aligners' delivery",
  // tooltip_payment:
  //   "You can always change the payment method of your patient if you go to Patients-Edit (at the end of the page) and then select the payment method",
  // tooltip_notifications: "Here you receive all notifications",
  // tooltip_3d_plan_approval_date:
  //   "Your aligners will be delivered within 15 business days after this date",
  // tooltip_precise_first_comment:
  //   "First comment from 3D Precise - {labelTitle}  ",
  // tooltip_precise_last_comment: "Latest comment from 3D Precise - {labelTitle}",
  // "required.fields.tooltip":
  //   "All fields marked with an asterisk are mandatory as the more information we receive on your treatment goals, the more accurate 3D plan we will be able create. Regardless, if you don’t feel comfortable completing all non-mandatory fields, please know that our advanced AI will provide you with the best possible 3D plan based on Andrew's six keys of occlusion and anatomical boundaries.",
  // "course.type.tooltip":
  //   "Case type is not only determined by length of treatment, but by movement type. Please refer to this ",
  // "course.type.tooltip.sec":
  //   "If after the case has been submitted the case type needs to be adjusted, we will communicate it promptly to you.",
  // "tooltip.chart": "chart",
  // "upload.photos.tooltip":
  //   "High-quality pictures are crucial to treatment plan accuracy. Please see the ",
  // "upload.photos.manual.tooltip": "manual",
  // "cbct.upload.tooltip":
  //   "Click to see the video instruction how to upload CBCT files",
  // "elastics.tooltip":
  //   "Optional for most movements. If you leave this field blank, our technician will inform you if they are needed upon creating the 3D plan",
  // "tooltip.correction.btn.title":
  //   "The following conditions must be met in order to submit a correction: ",
  // "tooltip.correction.btn.payment.status":
  //   "1.You must pay for the initial case.",
  // "tooltip.correction.btn.warranty.date":
  //   "2.The number of refinements has not exceeded the allowed amount. ",
  // "tooltip.correction.btn.error":
  //   "If these conditions have been met and you are unable to submit a refinement, please call ",
  // "tooltip.corrcetion.btn.support":
  //   "To order a refinement, please reach out to support (the chat on the bottom right) to upgrade the case to Predict Full and pay the difference",
  // "tooltip.correction.btn.no.course":
  //   "Please submit a patient to be able to order correction",
  // "tooltip.retainers":
  //   "You are not able to order retainers if your case type is “Retainers”. If you want to change it, please call ",
  // "tooltip.support": "3D Predict Support",
  // "tooltip.click.approve": "Сlick to approve",

  // Left menu
  "left.menu.header": "Doctor Account",
  "left.menu.patients": "Patients",
  "left.menu.add_patient": "Submit a patient",
  "left.menu.shipments": "My shipments",
  "left.menu.my_payments": "My payments",
  "left.menu.best_case": "Case of the quarter",
  "left.menu.library": "Library of clinical cases",
  // Top menu
  "top.menu.profile": "Profile",
  "top.menu.payment": "Payments",
  "top.menu.my_practices": "My Dental offices",
  "top.menu.add_practices": "Add Dental office",
  "top.menu.logout": "Log out",
  // Patient table header
  "pat_table.block_head": "Patients",
  "pat_table_tasks.block_head": "Current tasks",
  "pat_table.name": "Patient's name",
  "pat_table.delivered": "Steps delivered",
  "pat_table.received": "Payment received",
  "pat_table.tot_payment": "Total payment",
  "pat_table.payments": "Payments",
  "pat_table.status": "Patient's status",
  "pat_table.of": "out of",
  "pat_table.total": "Total",
  "pat_table.services": "Services",
  "pat_table_tasks.block_payments": "Payments",
  "pat_table.block_banners": "News",
  // Clinics table header
  "clin_table.header": "My dental officeS",
  "clin_table.name": "Name",
  "clin_table.address": "Address",
  "clin_table.phone": "Phone number",
  "clin_table.email": "Email",
  "clin_table.contacts": "Contacts",
  // Clinic view/edit
  "clin_view.header": "DENTAL OFFICE INFORMATION",
  "clin_view.edit.header": "EDIT CLINIC",
  "clin_view.name": "Dental office",
  "clin_view.address": "Dental office address",
  "clin_view.phone": "Dental office phone number",
  "clin_view.zip": "Zip code",
  "clin_view.email": "Dental office email",
  "clin_view.contacts": "Contact details",
  "clin_view.add.header": "Add dental office",
  "clin_view.name.placeholder": "Name",
  "clin_view.address.placeholder": "Dental offices address",
  "clin_view.zip.placeholder": "Zip code",
  "clin_view.phone.placeholder": "Dental office phone number",
  "clin_view.email.placeholder": "Dental office email",
  "clin_view.logistic_phone.placeholder":
    "Phone number for delivery service (at most 15 characters are allowed)",
  "clin_view.logistic_contact.placeholder":
    "Contact for delivery service (at most 35 characters are allowed)",
  "clin_view.logistic_phone": "Phone number for delivery service",
  "clin_view.logistic_contact": "Contact for delivery service",
  "clin_view.contacts.placeholder":
    "Contact person for invoices and contracts ",
  "clin_view.add.button": "Add Dental office",
  "clin_view.confirm": "Do you really want to detach the dental office?",
  "clin_view.schedule.schedule": "Schedule",
  "clin_view.schedule.monday": "Monday",
  "clin_view.schedule.tuesday": "Tuesday",
  "clin_view.schedule.wednesday": "Wednesday",
  "clin_view.schedule.thursday": "Thursday",
  "clin_view.schedule.friday": "Friday",
  "clin_view.schedule.saturday": "Saturday",
  "clin_view.schedule.sunday": "Sunday",
  "clin_view.schedule.from": "From",
  "clin_view.schedule.to": "To",
  "clin_view.address.line.1": "Address Line 1",
  "clin_view.address.line.2": "Address Line 2",
  "clin_view.address.line.3": "Address Line 3",
  "clin_view.address.not.more.then.35": "(at most 35 characters are allowed)",
  "clin_view.address.line.1.placeholder": "Address Line 1",
  "clin_view.address.line.2.placeholder": "Address Line 2",
  "clin_view.address.line.3.placeholder": "Address Line 3",
  "clin_view.detach": "Detach",
  "clin_view.delivery.contacts": "Delivery contacts",
  "clin_view.administrative.contacts": "Administrative contacts",

  "clin_view.error.35.symbols": "At most 35 characters are allowed",
  "clin_view.error.phone": "Invalid phone number",
  "clin_view.error.email": "Invalid email format",
  "clin_view.error.index": "Invalid zip code format",
  "clin_view.error.logistic.phone":
    "At most 15 digits are allowed. Only digits are allowed.",
  "clin_view.error.logistic.contact": "At most 35 characters are allowed",
  clinics_not_found: "No records found",
  // Right blocks patient page
  "pat.block.not_found": "Patient not found",
  "pat.block.right.header": "Patient",
  "pat.block.right.id": "ID",
  "pat.block.right.name": "Name",
  "pat.block.right.instructions": "Submission date",
  "pat.block.right.plan_approval": "3D plan approval date",
  "pat.block.right.aligners.eta": "Aligners ETA",
  "pat.block.right.additional.eta": "Replacement aligners ETA",
  "pat.block.right.retainers.eta": "Retainers ETA",
  "pat.block.right.delivered": "Steps delivered",
  "pat.block.right.correction": "Treatment correction",
  "pat.block.right.additional_course": "Additional course",
  "pat.block.right.additional": "Next steps",
  "pat.block.right.general.treatment": "Main treatment",
  "pat.block.right.start_date": "Start date",
  "pat.block.right.plan_date": "3D-plan is to be ready",
  "pat.block.right.caps_date": "Aligners are to be sent",
  "pat.block.right.warranty_end_date": "Warranty end date",
  "pat.block.status.header": "PATIENT'S STATUS",
  "pat.block.deliveries.header": "DELIVERIES",
  "pat.block.impression.delivery.header": "IMPRESSIONS SHIPMENT",
  "pat.block.impression.delivery.all": "all shippings",
  "pat.deliveries.button.noWaybills": "Shipping labels were not found",
  "pat.block.deliveries.waybills": "Tracking number",
  "pat.block.status.status": "Status",
  "pat.block.payment.header": "PAYMENT STATUS",
  "pat.block.payment.status": "Payment status",
  "pat.block.payment.received": "Payment received",
  "pat.block.payment.total": "Total payment",
  "pat.block.payment.currency": "_",
  "pat.block.payment.currency.usa": "$",
  "pat.block.payment.currency.curr": "$",
  "pat.block.payment.payments": "Case",
  "pat.block.payment.extras": "Extra Services",
  "pat.block.payment.pay.all": "Pay for the course",
  "pat.deliveries.button.callACourier": "Schedule a pickup",
  "pat.deliveries.button.callACourierByPhone": "Call a courier by phone",
  "pat.deliveries.button.callCourier.confirmation": "Confirm",
  "pat.deliveries.button.getWaybill": "Create a shipping label",
  "pat.deliveries.button.getNewWaybill": "Create new shipping label",
  "pat.deliveries.button.cancel": "Cancel",
  "pat.block.deliveries.estimated.pickup_date": "Estimated pickup date",
  "pat.block.deliveries.current.pickups": "Track a package",
  "pat.block.deliveries.see.waybill": "(print shipping label)",
  "pat.block.deliveries.aligner.delivery": "Aligners delivery tracking number",
  "pat.block.deliveries.aligner.all": "all deliveries",
  "pat.block.deliveries.type": "Type",
  "pat.block.deliveries.type.laboratory": "Impressions shipment",
  "pat.block.deliveries.type.clinic": "Aligners delivery",
  "pat.block.deliveries.shipment.date": "Pickup date",
  "pat.block.deliveries.shipments.aligners": "Aligners Shipment",
  "pat.block.deliveries.deliveries.impressions": "Impressions Shipment",
  "pat.shipments.tracking_number": "Tracking number",
  "pat.shipments.eta": "Aligners ETA",
  "pat.shipments.header": "Shipments",
  "pat.shipments.description":
    "Here you can track the aligners' delivery. Usually, it takes 12 business days after 3D Plan approval",
  "pat.shipments.selected.date": "Start from",
  "notify.shipments.error": "Error occurred trying to fetch shipments",
  "pat.block.deliveries.ups.site.track": "Track the status of the shipment",
  "pat.deliveries.contact.to.manager":
    "Please contact your Sales representative to generate a shipping label",
  "pat.deliveries.error.E_INVALID_FROM": "Incorrect information",
  "pat.deliveries.error.E_UNHANDLED_ERROR": "Service temporarily unavailable",
  "pat.deliveries.error.E_SERVICE_UNAVAILABLE":
    "Service temporarily unavailable",
  "pat.payments.amount.to.pay": "Amount to be payed",
  "pat.payments.buttons.pay": "Proceed with payment",
  "pat.payments.buttons.submit": "Submit",
  "pat.payments.online.payment": "Online Payment",
  "pat.payments.you.will.be.redirected":
    "You will be redirected to the page of the payment form",
  "pat.payments.course.paid": "Payment completed successfully",
  "pat.payments.course.paid.error":
    "An error occurred during payment transaction",
  "pat.payments.course.pay.course": "Payments",
  "pat.payments.course.status.error": "Something went wrong loading payment status",
  "pat.payments.course.status.canceled": "Payment was canceled",
  "pat.payments.course.status.pending": "Payment has been created and is awaiting payment",
  "pat.payments.course.status.not_found": "Payment not found",
  "pat.retainers.page.header": "Retainers",
  "RETAINERS_COMMENT" : "Comment",
  "pat.retainers.page.header.usa":
    "Retainers (Delivery takes 15 business days after submitting a form)",
  "pat.additional.aligners.page.header.usa": "Replacement Aligners ($25 each)",
  "pat.additional.aligners.page.header": "Replacement Aligners",
  "pat.payments.dear.doctor": "Dear Dr. ",
  "pat.payments.thanks": "Thank you ",
  "pat.payments.payment": "for your payment!",
  "pat.payments.invoice": "You’ll receive your invoice via email shortly",
  "pat.payments.payment_status.goto": "Go to patient",

  "notify.ok": "Ok",
  "notify.done": "Done",
  "pat.additional.aligners.add.stage": "Add stage",
  "pat.additional.aligners.required.fields": "Complete the required fields",
  "pat.additional.aligners.required.fields.remove.stage":
    "Complete the required fields or remove the additional stage",

  //Right blocks next step status
  FIRST_3D_PLAN: "Expected 3D-plan date",
  NEXT_3D_PLAN: "Expected 3D-plan date",
  FIRST_SHIPMENT: "Shipment expected",
  NEXT_SHIPMENT: "Next shipment",
  NEXT_SHIPMENT_PAYMENT_EXPECTED: "Waiting for payment",
  // Patient view-page-only blocks
  "pat.comment.header": "ADD COMMENT",
  "pat.comment.title": "Comments ",
  "pat.comment.placeholder": "Comment...",
  "pat.comment.button": "Add a comment",
  "pat.comments.hide": "Hide comments",
  "pat.comments.show": "See all comments",
  "pat.plan.header": "3D PLAN",
  "pat.plan.button": "View 3D plan",
  "pat.plan.link": "Share 3D plan",
  "pat.plan.copy_link": "Copy link",
  "pat.view.header": "PATIENT CARD",
  "pat.status.corr_stage": "Current step",
  "pat.status.button.correction": "Order correction",
  "pat.status.button.additional_course": "Order additional course",
  "pat.status.button.correction.new": "Order correction (New form)",
  "pat.status.button.retainers": "Order retainers",
  "pat.status.button.aligners.additional": "Replacement aligners",
  "pat.status.button.end": "Finish treatment",
  "pat.question.button": "Ask a clinical expert",
  "pat.question.modal.title": "Ask a clinical expert",
  "pat.question.modal.restricted.sym":
    'The following characters "<" ">" cannot be used',
  "pat.comment.rework.header": "Please, leave a comment in the field below",

  // Status names
  S_UNFILLED_CASE: "Unfilled case",
  S_INSTRUCTIONS_PROCESSING: "Processing instructions",
  S_RETAKE_IMPRESSIONS: "Doctor's Input Required",
  S_WAITING_FOR_A_PAYMENT: "Waiting for payment",
  S_3D_SCANNING: "3D scanning",
  S_3D_PLAN_MODELING: "3D plan modeling",
  S_3D_PLAN_APPROVAL: "Approve 3D plan",
  S_3D_PLAN_MODELS_BUILDING: "Treatment in progress",
  S_PRODUCTION_PRINTING: "Treatment in progress",
  S_PRODUCTION_MOLDING: "Treatment in progress",
  S_PRODUCTION_MARKING: "Treatment in progress",
  S_PRODUCTION_ALLOCATING: "Treatment in progress",
  S_PRODUCTION_FABRICATING: "Treatment in progress",
  S_PRODUCTION_QUALITY_CONTROL: "Treatment in progress",
  S_FINAL_STAGE_READY: "Treatment in progress",
  S_TREATMENT_FINISHED: "Treatment finished",
  S_CANCEL_TREATMENT: "Cancelled treatment",
  S_RETAINERS_ORDERED: "Clear retainers ordered",
  S_RETAINERS_PRINTING: "Clear retainers production",
  S_RETAINERS_FABRICATING: "Clear retainers production",
  S_RETAINERS_READY: "Clear retainers are ready",
  S_RETAINERS_3D_SCANNING: "Clear retainers production",

  // Create patient page
  "BLOCKHEAD_DOCTOR"                  : "Doctor's information",
  "HEADER_DOCTOR"                     : "Doctor",
  "HEADER_CLINIC"                     : "Dental office",
  "CLINIC_SHIP_COMMENTS"              : "Shipment comments ",
  "CLINIC_SHIP_COMMENTS_PLACEHOLDER"  : "Comments",
  "BLOCKHEAD_PAYMENT"                 : "Payment",
  "HEADER_PAYMENT_METHOD"             : "Payment method",
  "HEADER_PAYER"                      : "Payer",
  "PAYER_CLINIC"                      : "Clinic",
  "PAYER_DOCTOR"                      : "Doctor",
  "PAYER_PATIENT"                     : "Patient",
  "PM_CASH"                           : "Cash payment",
  "PM_BANK"                           : "Pay by invoice",
  "PM_INVOICE"                        : "Pay by invoice",
  "PM_CARD"                           : "Payment by credit card",
  "PM_CARD.usa"                       : "Pay with credit card online",
  "HEADER_PAYMENT_OPTION"             : "Payment option",
  "PO_ADVANCE"                        : "100% prepayment",
  "PO_SPLIT_2"                        : "2 step split payment",
  "PO_SPLIT_3"                        : "3 step split payment",
  "PO_CREDIT_SB"                      : " ",
  "PO_CREDIT_SB_COND"                 : " ",
  "PS_3D_PLAN"                        : "3D plan payment received",
  "PS_HALF_PRICE"                     : "50% steps payment received",
  "PS_FULL_PRICE"                     : "All steps payment received",
  "BLOCKHEAD_PAT_INFO"                : "Patient's information",
  "PAT_INFO_LAST_NAME"                : "Last name",
  "PAT_INFO_LAST_NAME_PLACEHOLDER"    : "Patient's last name",
  "PAT_INFO_NAME_TOOLTIP"             : "Changing patient's name is not allowed after case submission",
  "PAT_INFO_FIRST_NAME"               : "First name",
  "PAT_INFO_FIRST_NAME_PLACEHOLDER"   : "Patient's first name",
  "PAT_INFO_MIDDLE_NAME"              : "Middle name",
  "PAT_INFO_MIDDLE_NAME_PLACEHOLDER"  : "Patient's middle name",
  "PAT_EMAIL"                         : "Payer's email",
  "PAT_INFO_EMAIL_PLACEHOLDER"        : "Email",
  "HEADER_SEX"                        : "Gender",
  "SEX_F"                             : "Female",
  "SEX_M"                             : "Male",
  "HEADER_BDATE"                      : "Date of birth ",
  "HEADER_CONDITION"                  : "Patient's chief complaint / Сlinical condition ",
  "CONDITION_PLACEHOLDER"             : "Patient's chief complaint / Clinical condition",
  "BLOCKHEAD_INSTRUCTIONS"            : "Instructions",
  "HEADER_INSTRUCTIONS"               : "Case type",
  "ATTACHMENT_INSTRUCTIONS"           : "Choose one of the options",
  "ADDITIONAL_INSTRUCTIONS"           : "Additional services",
  "C_MATCHING"                        : "Matching of CBCT with Scan",
  "C_FULL"                            : "Unlimited",
  "C_FULL_V2"                         : "Unlimited",
  "C_FULL_COMMENT"                    : " ",
  "C_FULL_COMMENT_V2"                    : " ",
  "C_FULL_USA"                        : "PREDICT FULL",
  "C_FULL_COMMENT_USA"                : "Unlimited number of aligners, 3 refinements",
  "C_TEEN"                            : "Teen",
  "C_TEEN_COMMENT"                    : " (3D Plan, unlimited number of aligners, 3 refinements)",
  "C_SHORT"                           : "Short",
  "C_SHORT_COMMENT"                   : " (limited to 14 steps)",
  "C_SHORT_V2"                        : "Short",
  "C_SHORT_COMMENT_V2"                : " (limited to 14 steps)",
  "C_SHORT_USA"                       : "PREDICT SHORT",
  "C_SHORT_COMMENT_USA"               : "Limited to 14 Steps (no refinements included)",
  "C_ONE_JAW"                         : "Single arch",
  "C_ONE_JAW_COMMENT"                 : " ",
  "C_ONE_JAW_V2"                      : "Single arch",
  "C_ONE_JAW_COMMENT_V2"              : " ",
  "C_ONE_JAW_USA"                     : "PREDICT SINGLE ARCH",
  "C_ONE_JAW_COMMENT_USA"             : "Unlimited number of aligners for Single Arch, 2 refinements",
  "C_SUPER_SHORT"                     : "Super-short",
  "C_SUPER_SHORT_COMMENT"             : " (limited to 8 aligners for one jaw)",
  "C_SUPER_SHORT_V2"                     : "Super-short",
  "C_SUPER_SHORT_COMMENT_V2"             : " (limited to 8 aligners for one jaw)",
  "C_SUPER_SHORT_USA"                 : "PREDICT SUPER SHORT",
  "C_SUPER_SHORT_COMMENT_USA"         : "Limited to 8 aligners for Single Arch (no refinements included)",
  "C_UNLIMITED"                       : "Unlimited with Deep CBCT analysis and roots movement",
  "C_UNLIMITED_V2"                    : "Unlimited with Deep CBCT analysis and roots movement",
  "C_UNLIMITED_USA"                   : "DEEP CBCT",
  "C_UNLIMITED_COMMENT"               : " ",
  "C_UNLIMITED_COMMENT_V2"               : " ",
  "C_UNLIMITED_COMMENT_USA"           : "Root and bone-based treatment planning, unlimited number of aligners, 3 refinements",
  "C_3D_PLAN"                         : "3D Plan only",
  "C_3D_PLAN_V2"                         : "3D Plan only",
  "C_3D_PLAN_USA"                     : "3D Plan (for printing aligners in office)",
  "C_CHILDREN"                        : "Children",
  "C_CHILDREN_V2"                     : "Children",
  "C_CHILDREN_V3"                     : "Children",
  "C_CHILDREN_COMMENT"                : " ",
  "C_CHILDREN_COMMENT_V2"             : " ",
  "C_CHILDREN_COMMENT_V3"             : " ",
  "C_CHILDREN_SHORT"                  : " ",
  "C_CHILDREN_SHORT_V1"               : " ",
  "C_CHILDREN_SHORT_COMMENT_V1"       : " ",
  "C_RETAINERS"                       : "Retainers",
  "C_RETAINERS_COMMENT"               : " ",
  "C_RETAINERS_USA"                   : "Retainers",
  "C_RETAINERS_COMMENT_V2"            : " ",
  "C_RETAINERS_V2"                    : "Retainers",
  "RETAINERS"                         : "Retainers (2 pairs)",
  "RETAINERS_ONE"                     : "Retainers (1 pair)",
  "RETAINERS_ONE_ARCH"                : "Retainers",
  "RETAINERS_UPPER_ARCH"              : "Retainers / Upper Arch (2)",
  "RETAINERS_UPPER_ARCH_ONE"          : "Retainers / Upper Arch (1)",
  "RETAINERS_LOWER_ARCH"              : "Retainers / Lower Arch (2)",
  "RETAINERS_LOWER_ARCH_ONE"          : "Retainers / Lower Arch (1)",
  "RETAINERS_QUANTITY"                : "Number of retainer sets",
  "C_3DSMILE_PRO"                     : "3D Smile Pro",
  "C_3DSMILE_PRO_COMMENT"             : " ",
  "CBCT_ANALYSIS"                     : "Deep CBCT",
  "CBCT_ANALYSIS_3D_PLAN"             : "Deep CBCT",
  "DELIVERY"                          : "Shipping",
  "C_TEEN_V2"                         : "Teen",
  "C_TEEN_COMMENT_V2"                 : " ",
  "C_ANTHROPOMETRIC_DIAGNOSTIC"       : "Anthropometric analysis of 3D models (anthropometric diagnostic protocol of MSMSU)",
  "C_ANTHROPOMETRIC_DIAGNOSTIC_ITEM"  : "Anthropometric analysis of 3D models (anthropometric diagnostic protocol of MSMSU)",
  "C_IMPRESSIONS"                     : "I will be taking PVS (A-silicone) impressions",
  "C_SCAN"                            : "I will be taking intraoral scan",
  "C_YES_CT_CORRECTION"               : "Correction will be with CT",
  "C_NO_CT_CORRECTION"                : "Correction will be without CT",
  "C_DEEP_CBCT_ANALYSIS"              : "Deep CBCT",
  "C_DEEP_CBCT_ANALYSIS_ITEM"         : "Deep CBCT",
  "HEADER_ARCHES"                     : "Arches",
  "ARCHES_SELECT"                     : "Treat arches",
  "TA_BOTH"                           : "Both",
  "TA_UPPER"                          : "Upper",
  "TA_LOWER"                          : "Lower",
  "TA_BOTH_RETAINERS_USA"             : "Both (4 Upper retainers & 4 Lower retainers for $200)",
  "TA_UPPER_RETAINERS_USA"            : "Upper (4 upper retainers for $150)",
  "TA_LOWER_RETAINERS_USA"            : "Lower (4 lower retainers for $150)",
  "TA_BOTH_ADDITIONAL_USA"            : "Both ($50)",
  "TA_UPPER_ADDITIONA_USA"            : "Upper ($25)",
  "TA_LOWER_ADDITIONA_USA"            : "Lower ($25)",
  "ARCHES_U_SELECT"                   : "Upper arch form",
  "STAGE"                             : "Stage",
  "AMOUNT"                            : "The number of aligners",
  "UPPER_ARCH"                        : "Upper arch",
  "LOWER_ARCH"                        : "Lower arch",
  "SELECT_TREAT_ARCHES"               : "Treat arches",
  "TA_U_MAINTAIN"                     : "Maintain",
  "TA_U_EXPAND"                       : "Expand",
  "TA_U_NARROW"                       : "Narrow",
  "TA_U_EXPAND_TYPE_TORQUE"           : "Tipping",
  "TA_U_EXPAND_TYPE_BODILY"           : "Bodily",
  "TA_U_EXPAND_TYPE_HOWTO"            : "Achieve with",
  "TA_U_CORRECTION_BOTH"              : "tipping and bodily expansion",
  "TA_U_CORRECTION_TORQUE"            : "tipping expansion",
  "TA_U_CORRECTION_BODILY"            : "bodily expansion",
  "ARCHES_L_SELECT"                   : "Lower arch form",
  "TA_L_MAINTAIN"                     : "Maintain",
  "TA_L_EXPAND"                       : "Expand",
  "TA_L_NARROW"                       : "Narrow",
  "ARCHES_TLA"                        : "Tooth alignment",
  "TLA_G_MARGIN"                      : "Gingival margin",
  "TLA_C_EDGE"                        : "Incisal edge",
  "HEADER_INCISORS"                   : "Desired incisor relationship",
  "INCISORS_VO"                       : "Overbite",
  "VO_MAINTAIN"                       : "Maintain",
  "VO_DECREASE"                       : "Decrease",
  "VO_INCREASE"                       : "Increase",
  "VO_DECREASE_USA"                   : "Decrease (open up the bite)",
  "VO_INCREASE_USA"                   : "Increase (close up the bite)",
  "VO_COMMENT"                        : "How to change the depth",
  "VO_COMMENT_PLACEHOLDER"            : "Enter type and volume of movement",
  "INCISORS_HO"                       : "Overjet",
  "INCISORS_HO_U"                     : "Upper incisors",
  "HO_U_MAINTAIN"                     : "Maintain",
  "HO_U_RETROCLINATE"                 : "Retroclination",
  "HO_U_PROCLINATE"                   : "Proclination",
  "INCISORS_HO_L"                     : "Lower incisors",
  "HO_L_MAINTAIN"                     : "Maintain",
  "HO_L_RETROCLINATE"                 : "Retroclination",
  "HO_L_PROCLINATE"                   : "Proclination",
  "OVERJET"                           : "Overjet",
  "OJ_MAINTAIN"                       : "Maintain",
  "OJ_CONTACT"                        : "Contact between incisors",
  "OJ_MAINTAIN_LARGE"                 : "Maintain large overjet if needed for Class",
  "OJ_MAINTAIN_CORRECT"               : "Correct (create ideal contact between incisors)",
  "OJ_MAINTAIN_CORRECT_SPECIFY"       : "(Optional). Would you like to specify the type of movements to achieve this correction?",
  "CORRECT_RETROCLINATION_UPPER"      : "Retroclination of Upper incisors",
  "CORRECT_PROCLINATION_LOWER"        : "Proclination of Lower incisors",
  "CORRECT_PROCLINATION_UPPER"        : "Proclination of Upper incisors",
  "CORRECT_RETROCLINATION_LOWER"      : "Retroclination of Lower incisors",
  "MIDLINE"                           : "Midline",
  "ML_MAINTAIN"                       : "Maintain",
  "ML_ADJUST_LOWER_TO_UPPER"          : "Adjust lower to the upper (upper used as guidance)",
  "ML_ADJUST_UPPER_TO_LOWER"          : "Adjust upper to the lower (lower used as guidance)",
  "ML_IMPROVE"                        : "Improve by (list of required movements)",
  "HEADER_CANINE"                     : "Desired canine relationship",
  "CANINE_RIGHT"                      : "Right",
  "CR_R_I_CLASS"                      : "Class I",
  "CR_R_II_CLASS"                     : "Class II",
  "CR_R_III_CLASS"                    : "Class III",
  "CANINE_LEFT"                       : "Left",
  "CR_L_I_CLASS"                      : "Class I",
  "CR_L_II_CLASS"                     : "Class II",
  "CR_L_III_CLASS"                    : "Class III",
  "CANINE_HOWTO"                      : "Achieve class with",
  "CRH_DISTALIZE"                     : "Distalization",
  "CRH_MESIALIZE"                     : "Mesialization",
  "CRH_IPR"                           : "IPR",
  "CRH_AI"                            : "Let the AI and technician determine the best option",
  "CRH_SPECIFY"                       : "I would like to specify how to achieve it",
  "HEADER_MOLAR"                      : "Desired molar relationship",
  "MOLAR_RIGHT"                       : "Right",
  "MR_R_MAINTAIN"                     : "Maintain",
  "MR_R_I_CLASS"                      : "Class I",
  "MR_R_II_CLASS"                     : "Class II",
  "MR_R_III_CLASS"                    : "Class III",
  "MOLAR_LEFT"                        : "Left",
  "MR_L_I_CLASS"                      : "Class I",
  "MR_L_II_CLASS"                     : "Class II",
  "MR_L_III_CLASS"                    : "Class III",
  "MOLAR_HOWTO"                       : "Achieve class with",
  "MRH_DISTALIZE"                     : "Distalization",
  "MRH_MESIALIZE"                     : "Mesialization",
  "MRH_IPR"                           : "IPR",
  "MRH_AI"                            : "Let the AI and technician determine the best option",
  "IPR_TRUE"                          : "Use IPR",
  "HEADER_ELASTICS"                   : "Elastics",
  "HEADER_ELASTICS_DESCRIPTION"       : "3D Predict’s technology allows for a large range of movement volume, thus additional appliances are rarely needed. For more details please refer to this ",
  "ELASTICS_RIGHT"                    : "Right",
  "ER_R_I_CLASS"                      : "Class I",
  "ER_R_II_CLASS"                     : "Class II",
  "ER_R_III_CLASS"                    : "Class III",
  "ER_NONE"                           : "None",
  "ELASTICS_LEFT"                     : "Left",
  "BUTTONS_AND_HOOKS"                 : "Use hooks or buttons",
  "BUTTON"                            : "Button",
  "HOOK"                              : "Hook",
  "CANCEL"                            : "click to remove",
  "ER_L_I_CLASS"                      : "Class I",
  "ER_L_II_CLASS"                     : "Class II",
  "ER_L_III_CLASS"                    : "Class III",
  "HEADER_CLOSE_ALL"                  : "Close all spaces",
  "CAG_YES"                           : "Yes",
  "CAG_MAINTAIN"                      : "Maintain diastema / trema",
  "CAG_LEAVE_SPACE"                   : "Leave space distal to :   ",
  "CAG_LEAVE_SPACE_FOR"               : "Leave space for",
  "CAG_LEAVE_SPACE_ADD_COMMENT"       : "Any additional comments",
  "TOOTH_NUMBER"                      : "tooth",
  "DISTANCE_IN_MILLIMETRES"           : "distance in millimetres",
  "MILLIMETRES"                       : "mm",
  "ADD_FIELD"                         : "Add field",
  "CAG_LEAVE_SPACE_COMMENT"           : "(specify tooth and space in millimetres)",
  "EXTRA_NOMOVE"                      : "Do not move these teeth (bridges, ankylosed teeth, implants, etc.)",
  "EXTRA_NOLOCK"                      : "Avoid attachments on these teeth (facial restorations, crowns, veneers, etc.)",
  "EXTRA_EXTRACT"                     : "I want to extract these teeth",
  "EXTRA_HOOK"                        : "Cut a hook on these teeth",
  "EXTRA_BUTTON"                      : "I will bond buttons on these teeth",
  "EXTRA_TEETHING"                    : "To leave the place for a teething",
  "EXTRA_COMMENTS"                    : "Treatment goals / RX ",
  "treatment_goals_ex"                : "click to see examples for the field",
  "treatment_goals_ex_1"              : "Chief complaint is anterior crowding. Please align anterior teeth. Correct overbite and overjet within limits of patient’s anatomy.",
  "treatment_goals_ex_2"              : "Treatment goal is to align midlines and achieve class I molar. Note upper midline in on to the face so please match lower midline to it as much as possible.",
  "treatment_goals_ex_3"              : "Treatment goal is to make space for implant to replace #4. Space needed for crown restoration after implant is 7 mm.",
  "EXTRA_COMMENTS_PLACEHOLDER"        : "Comments",
  "UPLOADED_FILES"                    : "STL, Photos, OPG and other media",
  "UPLOADED_CBCT"                     : "CBCT files",
  "CBCT_LINKS"                        : "CBCT links",
  "LINK"                              : "Link",
  "CREATED_AT"                        : "Added",
  "OPEN"                              : "Copy",
  "UPLOAD_FILES"                      : "Upload STL, panoramic and other records",
  "UPLOAD_FHOTOS"                     : "Upload photos",
  "UPLOASES_FILES_EXIST"              : "Uploaded in iTero",
  "ADD_LINK_TO_CT"                    : "CBCT link",
  "UPLOADED_3SHAPE"                   : "Connect to 3Shape",
  "UPLOADED_MEDIT"                    : "Connect to Medit",
  "UPLOADED_PREXION"                  : "Please contact the Prexion (650) 212-0314",
  "COMING_SOON"                       : "Coming soon...",
  "UPLOADED_ITERO"                    : "Please call iTero Support ",
  "ITERO_SUPPORT"                     : " and provide this ID ",
  "ITERO_PHONE"                       : "1-800-577-8767 {br}",
  "ITERO_ID"                          : "177844",
  "LINK_TO_MANUAL"                    : "Open a manual",
  "UPLOAD_BTN"                        : "Upload image",
  "UPLOAD_FILE_BTN"                   : "Upload file",
  "DISK_CBCT"                         : "CBCT files on a disk",
  "PHOTO_PROFILE"                     : "Profile (not smiling)",
  "PHOTO_SMILE"                       : "Full face (smiling)",
  "PHOTO_PORTRATE"                    : "Full face (not smiling)",
  "PHOTO_UPPER_OCCLUSAL"              : "Upper occlusal",
  "PHOTO_LOWER_OCCLUSAL"              : "Lower occlusal",
  "PHOTO_RIGHT"                       : "Right view",
  "PHOTO_FRONT"                       : "Front view",
  "PHOTO_LEFT"                        : "Left view",
  "PHOTO_BEFORE"                      : "Before",
  "PHOTO_CORRECTION"                  : "Correction",
  "BTN_ADD_FILES"                     : "Add files",
  "BTN_CHOOSE_FILES"                  : "Choose files",
  "BTN_CHOOSE_PHOTO"                  : "Choose photo",
  "BTN_SUBMIT"                        : "Submit",
  "BTN_SUBMIT_NEW"                    : "Submit",
  "BTN_EDIT"                          : "Edit",
  "PAYMENT_DATA"                      : "Payment data",
  "BTN_CHANGE_PASSWORD"               : "Change password",
  "BTN_SAVE"                          : "Save",
  "BTN_SAVE_DRAFT"                    : "Save as a draft",
  "BTN_SAVE_DRAFT_CONTINUE_LATER"     : "Save as a draft and continue later?",
  "BTN_SAVE_CONTINUE_LATER"           : "Save and continue later?",
  "REQUIRED_FIELDS_FOR_A_DRAFT"       : "Please complete the required fields",
  "EXIT_WITHOUT_SAVING"               : "Exit without saving?",
  "STAY_HERE"                         : "Stay here",
  "LEAVE_WITHOUT_SAVING_BACK_BTN"     : "Changes that you made may not be saved",
  "CBCT_UPLOAD_HEADER"                : "Upload CBCT files",
  "CBCT_UPLOADED_HEADER"              : "Uploaded files",
  "HOW_UPLOAD_CBCT"                   : "How to upload CBCT files",
  "CBCT_DETAILS"                      : "Click for details",
  "CBCT_UPLOAD_PROGRESS_TITLE"        : "Uploading {filesCount} file(s)",
  "CBCT_UPLOAD_COMPLETE"              : "Upload complete",
  "CBCT_UPLOAD_ERROR"                 : "Upload error",
  "SCAN_UPLOAD_SCAN_WARNING"          : "Don’t forget to upload the scan",
  "SCAN_UPLOAD_CT_WARNING"            : "Don’t forget to upload the CBCT",
  "3D_SMILE_PRO_WARNING"              : " ",
  "ADDITIONAL_ALIGNERS"               : "Replacement aligners",
  "SAA_PROCESSING"                    : "Processing",
  "SAA_READY"                         : "Replacement aligners are ready",
  "all.additional.aligners"           : "all replacement aligners",


  // Footer
  "page.footer.header": "© OOO «DOMMAR».",
  "page.footer.header.usa": "© 3D Smile USA Inc.",
  "page.footer.content": "3D Smile. High technologies for tooth movement!",
  "page.footer.content.usa":
    "3D Predict. See the unseen. Look beyond the evident.",
  "page.footer.contacts": "Contacts",
  "page.footer.payment": "Payment",
  "plan.approve.header": "Approve 3D-plan",
  "plan.approve.body": "Approve plan:",
  "plan.approve.btn": "Approve",
  "plan.confirm_plan.btn": "Confirm",
  "plan.approve.no_clinic":
    "The patient profile does not include a dental office.",
  "plan.approve.redirect": "Go to profile",
  "plan.approve.accept":
    "I confirm that I have read and agree to the Terms and Conditions",
  "plan.approve.postpone": "Postpone confirmation",
  "plan.rework.header": "Adjust 3D-plan",
  "plan.rework.body": "Plan ID:",
  "plan.rework.btn": "Adjust",
  "plan.cancel.header": "Correct 3D-plan",
  "plan.cancel.body": "3D-plan:",
  "plan.cancel.stage": "Step number",
  "plan.cancel.scroll.to.field": "Click to edit",
  "plan.cancel.btn": "Submit",
  "plan.order.btn": "Order",
  "change.password": "Change password",
  "change.password.match": "The passwords don't match",
  "enter.password": "Enter new password",
  "re-enter.password": "Re-enter new password",
  "treatment.correct.header": "Adjust treatment",
  "treatment.additional_course.header": "Additional course",
  "correction.ct.notifcation": "CBCT file was uploaded",
  "recipe.check.reference.steps": "Indicated step #",
  "recipe.check.reference.photos": "Uploaded photos",
  "recipe.check.reference.material": "Chose to use scans or impressions",
  "recipe.check.reference.arches": "Chose to treat both or one of the arches",
  "recipe.check.reference.correction_with_ct": "Correction with CT or not",

  "Ляшук Даниил": "Lyashuk Daniil",
  "Аптекарев Федор": "Aptekarev Fedor",
  "Дмитриенко Ирина": "Dmitrienko Irina",
  "Сосин Михаил": "Sosin Mikhail",

  "notify.doctor.update.success": "Information has been updated",
  "notify.doctor.update.error": "Error. Login already exists",
  "notify.doctor.password.success": "Password was successfully changed",
  "notify.doctor.password.failed": "Password change failed",
  "notify.comment": "Comment has been added",
  "notify.comment.error": "Error occurred trying to save comment",
  "notify.question": "Question has been sent",
  "notify.question.error": "Error occurred trying to send question",

  "notify.patient.create.ok": "A new patient has been created",
  "notify.patient.create.error": "Creation error",
  "notify.patient.create.draft.patient": "A patient ",
  "notify.patient.create.draft.ok"    : " has been saved as a draft, you can continue filling out the prescription in 'Patients'",
  "notify.patient.update.ok"          : "Patient successfully updated",
  "notify.patient.update.error"       : "Patient update error",

  "notify.add_clinic.ok"              : "Dental office list was updated",
  "notify.add_clinic.error"           : "An error occurred trying to attach dental office",

  "notify.unlink_clinic.ok"           : "Dental office has been detached",
  "notify.unlink_clinic.error"        : "Error occured trying to detach dental office",

  "notify.correction.create.ok"       : "A new correction was created",
  "notify.form.submitted.ok"          : "for submitting the form! We'll get back to you shortly",
  "notify.correction.create.error"    : "An error occurred trying to order correction",
  "notify.correction.thanks"          : "Thank you for using the new form to order correction. Feel free to share your feeedback feedback@3d-smile.com",

  "notify.status_end.ok"              : "Case has been finished successfully",
  "notify.status_end.error"           : "Error occurred trying to finish treatment",

  "notify.retainers.ok"               : "The retainers was ordered",
  "notify.retainers.error"            : "Retainers order error",

  "notify.additional.aligners.ok"     : "Replacement aligners has been ordered",
  "notify.additional.aligners.error"  : "An error occurred during ordering replacement aligners",

  "notify.approve.ok"                 : "3D Plan has been approved",
  "notify.approve.error"              : "Error occurred while trying to approve 3D plan",

  "notify.rework.ok"                  : "Thank you for your comments. {labelTitle} technician is already working on your 3D Plan updates",
  "notify.rework.error"               : "Error occurred trying to adjust 3D Plan",

  "notify.doctor.subscribe"           : "Notification",
  "notify.doctor.subscribe.edit"      : "Subscription to notifications:",

  "notify.link_copy"                  : "Link copied to clipboard",
  "notify.link_tg"                    : "Link sent via Telegram",
  "notify.link_email"                 : "Link sent via email",
  "notify.link_tg_email"              : "Link sent via Telegram and email",

  "notify.send_password.EMAIL_SENT"                : "A one-time password has been sent to your email address",
  "notify.send_password.E_EMAIL_OR_LOGIN_REQUIRED" : "Please specify your email address or login",
  "notify.send_password.E_EMAIL_NOT_FOUND"         : "Your email address wasn't found",
  "notify.send_password.E_LOGIN_NOT_FOUND"         : "Your login wasn't found",
  "notify.send_password.SENDING_ERROR"             : "Error has occurred sending restore link. Please, check data.",
  "notify.send_password.SENDING_RESTORE_SUCCESS"   : "Restore link has been sent to your email",

  "notify.login.SUCCESS"              : "SUCCESS",
  "notify.login.E_PASSWORD_RESET"     : "Please, set a new password",
  "notify.login.E_INVALID_AUTH"       : "Incorrect login or password",

  "notify.upload.E_UNSUPPORTED_FORMAT" : "The format of the file that you are uploading is not supported. Please, upload the file in JPEG format.",
  "notify.upload.E_UNSUPPORTED_FORMAT_EXT" : "The format of the file that you are uploading is not supported. Please, upload the file in one of the following formats: JPG, JPEG, PNG, STL, PDF, ORTHO, MP4.",
  "notify.upload.E_UNSUPPORTED_FORMAT_CBCT" : "The format of the file that you are uploading is not supported. Please, upload compressed file in one of the following formats: ZIP, RAR.",
  "notify.upload.E_UNSUPPORTED_HIEF"   : "You are trying to upload a file in HEIF format. This file format is not supported. Please, set the camera of your smartphone to save images in JPEG format (Settings -> Camera -> Format -> Most compatible) and upload a JPEG file.",
  "notify.upload.E_FILE_TOO_BIG_CBCT" : "File size should be less than 1.5 GB.",

  "new_password.header" : "You entered with temporary password. Please set new password.",
  "new_password.header_key" : "Please, enter a password that contains at least 10 characters including at least one number, uppercase and lower case letters and at least one special character.",
  "new_password.new_password" : "New password",
  "new_password.confirm" : "Confirm password",
  "new_password.button" : "Save",

  "Privacy"                           : "Privacy",

  "nocorrection"         : "No prescription for this correction",
  "correction"           : "Correction",
  "main.treatment"       : "Main treatment",
  "correction.material"               : "Impressions",
  "correction.impressions.new"        : "New impressions / intraoral scan are taken",
  "correction.impressions.old"        : "No new impressions (modeling based on last stage)",
  "correction.reason"                 : "Correction cause",
  "correction.plan_type"              : "Which instructions to use to continue treatment",
  "correction.failed.teeth"           : "Planned movement was not achieved on the following teeth",
  "correction.old_plan"               : "Continue based on initial instructions",
  "correction.new_plan"               : "Correct instructions",
  "correction.information_correct"    : "Information is entered correctly",

  "correction.comment.title"          : "Order mid term correction",
  "correction.comment.plan"           : "Plan:",
  "correction.comment.tooth"          : "Tooth",
  "correction.comment.stage"          : "Stage:",
  "correction.comment.arches.both"    : "Both arches",
  "correction.comment.arches.upper"   : "Upper arch",
  "correction.comment.arches.lower"   : "Lower arch",
  "correction.comment.casts.new"      : "New impressions will be taken",
  "correction.comment.casts.old"      : "Modeling should be done based on the last stage",
  "correction.comment.impressions.scan"      : "New impressions will be taken (intraoral scan)",
  "correction.comment.impressions.impression"      : "New impressions will be taken (impressions)",
  "correction.comment.movment_1"      : "Failure to follow doctor's prescription",
  "correction.comment.movment_2"      : "Movement not achieved",
  "correction.comment.movment_type_0" : "movement not chosen",
  "correction.comment.movment_type_1" : "Intrusion",
  "correction.comment.movment_type_2" : "Extrusion",
  "correction.comment.movment_type_3" : "Rotation",
  "correction.comment.movment_type_4" : "Mesialization",
  "correction.comment.movment_type_5" : "Distalization",
  "correction.comment.movment_type_6" : "Torque",
  "correction.comment.movment_type_7" : "Angulation",
  "correction.comment.movment_type_8" : "Vestibular",
  "correction.comment.movment_type_9" : "Oral",
  "correction.comment.correct_plan_1" : "Continue treatment based on the initial instructions",
  "correction.comment.correct_plan_2" : "Continue treatment based on the new instructions",

  "DENTAL_NOTATION_HEADER"            : "Dental notation",
  "DENTAL_NOTATION_TYPE_FDI"          : "FDI",
  "DENTAL_NOTATION_TYPE_PALMER"       : "Palmer notation",
  "DENTAL_NOTATION_TYPE_UNIVERSAL"    : "Universal numbering system",

  "preferences"                               : "Clinical Preferences",
  "preferences.ipr"                           : "IPR",
  "preferences.ipr_enabled"                   : "Perform IPR",
  "preferences.ipr_start_step"                : "Start IPR on step",
  "preferences.attachments"                   : "Attachments",
  "preferences.attachments_delay"             : "Place attachments on step",
  "preferences.attachments_deprecated_teeth"  : "Never place attachments on teeth",
  "preferences.expansion"                     : "Expansion",
  "preferences.always_expand"                 : "Always expand",
  "preferences.incisor_relationship"          : "Incisor relationship",
  "preferences.incisor_relationship.overjet"  : "Incisor relationship: overjet, mm",
  "preferences.incisor_relationship.overbite" : "Incisor relationship: overbite, mm",
  "preferences.upper_incisor_angulation"      : "Upper incisors torque (inclination), degrees",
  "preferences.additional_notes"              : "Additional notes",
  "preferences.additional_notes.sub"          : "Other clinical preferences for most of your cases",
  "preferences.overcorrection"                : "Overcorrection",
  "preferences.deep_bite_intrusion"           : "Intrusion (deep bite) +1 mm",
  "preferences.incisors_extrusion"            : "Incisors extrusion +1 mm",
  "preferences.rotation_over_30dg"            : "Rotation over 30deg +5",

  "profile.unable_change_email"               : "Please contact us at support@3dpredict.com or contact your Sales representative if you would like to change your email.",
  "comment.message.on.plan.approve"           : "You can leave a comment regarding the provided 3D-plan while viewing it",

  "comment.on.3dplan.adjustment"              : "3D Plan comment. Version",
  "COMMENT_TYPE_CE_QUESTION"                  : "Question to a clinical expert",
  "COMMENT_TYPE_CORRECTION"                   : "Correction instructions",
  "COMMENT_TYPE_REWORK"                       : "3D Plan comment",
  "COMMENT_TYPE_REWORK_PRESICE"               : "3D Plan comment / 3D Precise",
  "COMMENT_TYPE_ORDER_RETAINERS"              : "Retainers",

  "offer.header"                              : "Public Offer Agreement",
  "offer.predict.header"                      : "3D Predict's Terms and Conditions",
  "offer.accept"                              : "I confirm that I have read, understand and agree to the Public Offer Agreement",
  "offer.accept.predict"                      : "I confirm that I have read, understand and agree to 3D Predict's Terms and Conditions",
  "offer.confirm_plan.btn"                    : "Confirm",
  "offer.pdf_error"                           : "Something went wrong while loading the offer",
  "notify.doctor.offer.error"                 : "Error was occurred while trying to accept an offer",
  "notify.doctor.offer.success"               : "Offer has been successfully accepted",
  "page.footer.offer"                         : "Public Offer Agreement",
  "page.predict.footer.offer"                 : "3D Predict's Terms and Conditions",

  "manuals.title"                             : "Manuals",
  "manuals.empty.query"                       : "No published manuals on your language yet.",
  "manuals.loading"                           : "Loading...",
  "notify.patient.payment_link.error"         : "Error was occurred while trying to process payments",
  "patient.area.payments.paid"                : "Payment is successfully processed",
  "another.payer.person"                      : "Please, specify payer's full name if payer is not the patient",
  "PAYER_LAST_NAME"                           : "Last name",
  "PAYER_LAST_NAME_PLACEHOLDER"               : "Payer's last name",
  "PAYER_FIRST_NAME"                          : "First name",
  "PAYER_FIRST_NAME_PLACEHOLDER"              : "Payer's first name",
  "PAYER_MIDDLE_NAME"                         : "Middle name",
  "PAYER_MIDDLE_NAME_PLACEHOLDER"             : "Payer's middle name",

  "E_LOGIN_REQUIRED"                          : " ",
  "EXTRA_SERVICE_OPTION_LAST_STAGE"           : "Use the patient’s last 3D Plan stage",
  "EXTRA_SERVICE_OPTION_USE_SCAN"             : "I will be taking intraoral scan",
  "EXTRA_SERVICE_OPTION_USE_IMPRESSIONS"      : "I will be taking PVS (A-silicone) impressions",

  //Doctor payments info
  "payments.account.delete.err":
    "Error occurred while trying to remove payment information",
  "payments.doctor.total": "Total:",

  //Additional Aligners Table
  "th.stage": "Stage",
  "th.amount": "Amount",
  "th.option": "Arches",
  "th.status": "Status",
  "th.timestamp": "Order date",

  "integrations.my.scanners": "My scanners",
  "integration.my.scanners.description":
    "In this section you can connect your external scanners' accounts with {labelTitle} Doctor Portal",
  "pat.medit.auth": "Medit authorization",
  "pat.medit.auth.success": "Successfully connected to Medit",
  "medit.auth.err": "Error occurred while trying to connect to Medit",
  "th.medit.patientId": "Patient's ID",
  "th.medit.status": "Status",
  "th.medit.caseName": "Case name",
  "th.medit.patientName": "Patient’s name",
  "th.medit.date.updated": "Last modified date",
  "th.medit.date.scanning": "Scanning date",
  "th.medit.date.ordered": "Ordered date",
  "th.medit.action": "Actions",
  "th.medit.action.submit": "Upload to new RX",
  "th.medit.action.connect": "Upload to existing RX",
  "th.medit.action.upload": "Upload additional files",
  "th.medit.case.connected.to": "Case is connected to ",
  "th.medit.case.reconnected.to": "Reconnect to another patient ",
  "th.medit.action.disconnect": "Disconnect",
  "th.medit.action.close": "Back to Medit list",
  "th.medit.action.redirect": "Go to patient's RX",
  "th.medit.connect.patient": "Medit files' upload",
  "th.medit.action.сhoose.file": "Select files",
  "th.medit.no.files": "There are no other scan files available in this case",
  "th.medit.action.сhoose.patient": "Select {labelTitle} patient",
  "th.medit.patient.create": "Patient is being created...",
  "th.medit.patient.connect": "Patient is being connected...",
  "th.medit.patient.connect.success":
    "A new patient has been connected to this case",
  "th.medit.file.upload_success": "Your Medit files have been uploaded",
  "th.medit.upload_success": "Medit files",
  "th.medit.file.upload_error": " has not been uploaded",
  "th.medit.file.name": "File ",
  "th.medit.file.uploading": " is being uploaded...",
  "th.medit.file.uploaded": " has been uploaded",
  "th.medit.uploaded.files": "Uploaded files",
  "th.medit.patient_name": "Patient name",
  "medit.title": "MEDIT recent scans",
  "medit.patient.pick": "Check for new scans",
  "medit.search.message": "No cases found",
  "th.medit.first.note":
    "Please note that the Medit case is already connected to the {labelTitle} case ",
  "th.medit.second.note":
    " If you proceed with the file upload, this connection will be changed.",
  SCAN: "Scan file",
  CAD: "Cad file",
  CAM: "Cam file",
  MILLING: "Milling file",
  ATTACHED: "Attached file",
  upload_files_to_patient_card: "Upload from Medit",
  "patient.search.placeholder": "Start typing a patient name or ID",

  // 3SHAPE
  "pat.3shape.auth": "3Shape authorization",
  "pat.3shape.auth.success": "Successfully connected to 3Shape",
  "th.3shape.patientName": "Patient’s name",
  "th.3shape.threeShapeOrderNo": "Order Number",
  "th.3shape.receivedOn": "Received On",
  "th.3shape.version": "Version",
  "3shape.auth.err": "Error occurred while trying to connect to 3Shape",
  "th.3shape.scan.source": "Scanner",
  "th.3shape.application": "Application",
  "th.3shape.files": "Files",
  "th.3shape.file.name": "Title",
  "th.3shape.file.status": "Status",
  "th.3shape.file.upload": "Upload",
  "th.3shape.file.uploaded": "Uploaded",
  "th.3shape.err": "Error occurred while trying to connect to 3Shape",
  "3shape.files": "3Shape Attachments",
  "3shape.patient.info": "3Shape Case Data",
  "3shape.patient.pick": "Choose 3Shape case that matches case",
  "3shape.upload.files.warning":
    "File upload will take some time, you can leave the page and continue working on other sections of your Doctor site.",
  "3shape.search.message": "No cases found",

  "bonus.patient": "Free case",
  "bonus.period": "Promotion period",
  "bonus.patients": "Free cases",
  "bonus.patients.total": "Cases:",
  "bonus.all.cases": "All cases",
  "bonus.cases.left": "cases added",
  "bonus.cases.left.1": "from",
  "bonus.cases.left.2": "patients",
  "bonus.case.chosen": "Free case was used",
  "bonus.case.not.chosen": "Free case was not chosen",
  "bonus.patient.set.successfully": "Patient is set as bonus successfully",
  "bonus.patient.set.error": "Error occurred choosing bonus patient",
  "bonus.patient.btn": "Free case",
  "bonus.period.end.days": "days till end of promotion period",
  "bonus.period.end.months": "months till end of promotion period",
  "bonus.patient.activation": "cases to go till Free case",
  "bonus.patient.activation.one": "case to go till Free case",
  "bonus.patient.activation.more": "cases to go till Free case",
  "bonus.patient.activation.a.lot": "cases to go till Free case",
  "bonus.full.coin.title": "Your Free case is now available",

  // bonuses page
  "bonuses.page.title": "Loyalty program",
  "bonuses.page.choose.event": "",

  "geo.usa.state": "State",
  "geo.usa.city": "City",

  "login.error": "Incorrect login or password",
  "password.recovery.invalid.link": "Link is invalid or expired",
  "password.recovery.title": "Password recovery",

  "password.requirements.header":
    "Please, enter password that matches following requirements",
  "password.requirements.digits": "contains digits",
  "password.requirements.upper.chars": "contains uppercase letters",
  "password.requirements.lower.chars": "contains lowercase letters",
  "password.requirements.length": "consist of at least 10 characters",
  "password.requirements.special": "contains special characters",
  "password.requirements.match": "entered passwords match",
  "password.requirements.similar": "not too similar to your login",
  "public.plan.error": "Error occurred trying to get public link",

  "p.patient.correction.stages": "Steps",
  "meetings.not.found": "Schedule is not found",
  "meetings.not.found.description": "Clinical experts available for appointment will be displayed here",
  "meetings.error": "Error loading schedule",
  "meetings.title": "Contact Clinical Expert (3D Predict)",
  "meetings.book": "Schedule a meeting",
  "meetings.available": "Available",
  "meetings.booked": "Booked",
  "meetings.choose.expert":
    "Schedule a zoom with one of our clinical experts to discuss the patient case you've submitted, your treatment goals, prescription or the 3D Plan.",
  "meetings.choose.expert.predict":
    "Schedule a zoom call with a clinical expert to discuss your 3D Predict patients: treatment goals and 3D Plan review.",
  "meetings.choose.expert.comment":
    "For all other questions, please contact Customer support at 8-******* or ***@3dpredict.com",

  "support.sidebar.title": "Contact Customer Support",
  "support.page.header": "Customer Support",
  "support.page.contacts.header": "Contact Our Support Team",
  "support.page.toll.free": "toll free number",
  "support.page.operating.hours": "Monday - Friday 9 AM - 6 PM EST",
  "support.page.onbording.btn": "Portal Onboarding",
  "support.page.onbording.btn.tooltip": "You will be redirected to the Calendly schedule page",
  "support.page.zoom.call.title": "Schedule a zoom call with customer support to learn more about our portal",

  "additional.aligners.both.comment": " ",

  events: "Education",
  "events.search.placeholder": "Name or city",
  "events.upcoming": "Schedule",
  "events.upcoming.offline": "Offline",
  "events.upcoming.empty.heading": "No events yet",
  "events.upcoming.empty.description": "Events available for registration will be displayed here",
  "events.upcoming.online": "Online",
  "events.upcoming.recording": "Video",
  "events.upcoming.recording.empty.heading": "No videos yet",
  "events.upcoming.recording.empty.description": "Available for puchase videos will be displayed here",
  "events.enrolled": "My events",
  "events.enrolled.upcoming": "Upcoming",
  "events.enrolled.upcoming.empty.heading": "No upcoming events yet",
  "events.enrolled.upcoming.empty.description": "You don't have any upcoming events. It's time to sign up",
  "events.enrolled.upcoming.empty.link": "Go to schedule",
  "events.enrolled.recording": "Available videos",
  "events.enrolled.recording.empty.heading": "No video yet",
  "events.enrolled.recording.empty.description": "You haven't purchased any videos yet. Time to add something to your collection",
  "events.enrolled.recording.empty.link": "Go to videos",
  "events.enrolled.visited": "Visited",
  "events.enrolled.visited.empty.heading": "No visited events yet",
  "events.enrolled.visited.empty.description": "The events you visited will be displayed here",
  "events.not_found.heading": "No events found",
  "events.not_found.description": "Sorry, we couldn't find any events matching your search",
  "events.price": "Price",
  "events.price.all": "All",
  "events.price.free": "Free",
  "events.price.paid": "Paid",
  "events.recording": "Video",
  "events.currency": "rub.",
  "events.free": "Free",
  "events.more_details": "Learn more",
  "events.action.pay": "Pay",
  "events.action.pay.error": "Something went wrong while receiving the payment link. " +
                             "Try again later",
  "events.action.zoom_join": "Join",
  "events.action.buy": "Buy",
  "events.action.get": "Get",
  "events.action.signup": "Sign up",
  "events.action.watch": "Watch",
  "events.action.feedback": "Leave feedback",
  "events.badge": "unpaid events",
  "events.video_available": "Video available",
  "events.watch_modal.title": "Go on {service}?",
  "events.watch_modal.description": "You are about to be redirected to {service} to watch the video",
  "events.watch_modal.open": "Go",
  "events.watch_modal.cancel": "Cancel",
  "events.zoom_modal.title": "Go on Zoom?",
  "events.zoom_modal.description": "you are about to be redirected to Zoom to join the meeting",
  "events.error.heading": "Error loading events",
  "events.error.description": "Try reloading the page or coming back later",
  "events.error.reset": "Retry",
  "events.not.found": "Events not found",
  "event.not.found": "Event not found",
  "events.rates.complimentary": "Complimentary",
  "events.rates.price": "Price",
  "events.rates.price.package": "Package price",
  "events.package.list": "Cycle events",
  "events.payments.package.pay": "Pay for the package",
  "events.enroll.success": "Registration process successfully completed",
  "events.enroll.error": "Error occurred trying to register to the event",
  "event.button.register": "Register",
  "event.button.zoom_join": "Join in Zoom",
  "event.payments": "Payment",
  "event.payments.money": "Total amount",
  "event.payments.smiles": "Price, smiles",
  "event.start.datetime": "Date and Time",
  "event.payment.smiles.total": 'Payment in "Smiles"',
  "events.payments.link.error": "Error occurred trying to proceed with a payment",
  "event.registration.payment.status": "Status",
  "event.registration.CREATED": "Request submitted",
  "event.registration.PROCESSING": "Waiting for a payment",
  "event.registration.PENDING": "Waiting for a payment",
  "event.registration.DECLINED": "Transaction declined",
  "event.registration.SUCCESS": "Payment completed ",
  "event.registration.CANCELED": "Canceled",
  "event.registration.enrolled": "Registered",
  "event.meeting_link": "Meeting link",
  "events.leave.feedback": "Leave a feedback about the event",
  "events.send.feedback.error": "Error occurred trying to send a feedback",
  "events.send.feedback.thanks": "Thank you for your feedback",
  "events.send.feedback.success": "Your feedback has been sent successfully!",
  "events.feedback.error": "Information not found",
  "events.payment.legal_entity": "Legal entity",
  "events.payment.email": "Email",
  "events.payment.email.hint": "(specify an email for which invoice is to be sent to)",
  "events.payment.email.doctor.hint": "(will be saved into your profile)",
  "events.payment.invoice.hint": "(if invoice will be paid by legal entity)",
  "events.offer.sign": "I confirm that I have read, understand and agree to ",
  "events.registration.completed": "No spots available",
  "events.registration.completed.verbose": "No spots available",
  "events.payment_status.goto": "Go to event",

  //tour
  "tour.take.tour": "Do you want to take 3D Predict Doctor Tour?",
  "tour.account":
    "Here is your profile. Please provide your clinical preferences to help us better understand your treatment goals",
  "tour.scanners": "Please integrate with the scanners if you have any of them",
  "tour.patient.new": "Here you can submit a patient",
  "tour.patients": "Here you can see the status of each of your patients",
  "tour.payment":
    "if you selected the payment by credit card, you are able to pay here. If you need an invoice, please drop a message in the chat below, and we’ll send it to you",
  "tour.chat": "Chat (please feel free to have a chat with us!)",
};

export default messages;
