// import * as Sentry from "@sentry/react";

/**
 * Sends an error to the server.
 * @param {unknown} error Exception, error object, or message.
 * @param {string} component Name of component.
 */
export default function remoteLog(error: unknown, component: string): void {
  // if (error instanceof Error) {
  //   Sentry.captureException(error, { tags: { component } });
  // } else {
  //   Sentry.captureMessage(String(error), { tags: { component } });
  // }

  const url = window.location.pathname + window.location.search;

  void fetch(`${process.env.API}/remote/log`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ content: String(error), component, url }),
  })
    .then()
    .catch();
}
