import { AcademicCapIcon } from "@heroicons/react/24/outline";
import * as Collapsible from "@radix-ui/react-collapsible";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import invariant from "tiny-invariant";

import { getBanners } from "../actions/banners";
import scanner from "../assets/img/scanner.svg";
import { deployedRussia, deployedUSA, isDevelopment } from "../common/utils";
import { API_STATIC_GET } from "../config";
import { useAppDispatch, useAppSelector } from "../store";
import { AlertMenuItem } from "./alert_menu_item";
import { useGetUnpaidEventsQuery } from "./doctor/events/event_api";
import styles from "./patient_menu.module.css";
import { AlertShipments } from "./profile_menu_shipments";
import RenderBanners from "./promotion/banners";
import { Badge } from "./ui/badge";

export default function PatientMenu() {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const smiles = useAppSelector((state) => state.smiles);
  const tour = useAppSelector((state) => state.tour);
  const banners = useAppSelector((state) => state.banners);
  const bannersLeft = banners.filter((banner) => banner.align == "left");

  const menuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    void dispatch(getBanners());
    const pathname = window.location.pathname;

    menuRef.current?.querySelectorAll("a").forEach((anchorEl) => {
      if (anchorEl.getAttribute("href") == pathname) {
        anchorEl.closest("li")?.classList.add("active");
      }
    });
  }, [dispatch]);

  return (
    <div className="page-sidebar-wrapper">
      <aside
        id="patient-menu-sidebar"
        className={tour ? "page-sidebar collapse show" : "page-sidebar navbar-collapse collapse"}
        style={{ top: isMobile ? 10 : 0 }}
      >
        {isMobile &&
        bannersLeft.length > 0 &&
        (deployedUSA() || (deployedRussia() && (window.location.hostname.endsWith(".ru") || isDevelopment))) ? (
          <RenderBanners banners={bannersLeft} mobile={true} />
        ) : null}

        <ul
          ref={menuRef}
          className="page-sidebar-menu"
          data-keep-expanded="false"
          data-auto-scroll="true"
          data-slide-speed={200}
        >
          <li className="nav-item start active">
            <a href="#" className="nav-link nav-toggle" id="sidebar">
              <i aria-hidden className="icon-home" />
              <span className="title">
                <FormattedMessage id="left.menu.header" />
              </span>
            </a>

            <ul className="sub-menu" id="sidebar-submenu">
              <li className="nav-item start">
                <NavLink to="/pages/patients" className="nav-link" id="sidebar-patients">
                  <i aria-hidden className="icon-users" />
                  <span className="title">
                    <FormattedMessage id="left.menu.patients" />
                  </span>
                </NavLink>
              </li>

              {deployedRussia() ? <AlertShipments titleLabel="left.menu.shipments" /> : null}

              <li className="nav-item start">
                <NavLink to="/pages/new_patient" className="nav-link" id="sidebar-patient-add">
                  <i aria-hidden className="icon-user-follow" />
                  <span className="title">
                    <FormattedMessage id="left.menu.add_patient" />
                  </span>
                </NavLink>
              </li>

              {deployedUSA() || (deployedRussia() && intl.locale == "ru") ? (
                <AlertMenuItem />
              ) : null}

              {deployedUSA() ? <AlertShipments titleLabel="left.menu.shipments" /> : null}

              {deployedUSA() ? (
                <li className="nav-item start">
                  <NavLink to="/pages/scanners" className="nav-link" id="sidebar-scanners">
                    <img style={{ width: 35, marginLeft: -8 }} src={scanner} alt="" />
                    <span style={{ marginRight: 2 }} className="title">
                      <FormattedMessage id="integrations.my.scanners" />
                    </span>
                  </NavLink>
                </li>
              ) : null}

              {deployedRussia() ? (
                <li className="nav-item-start">
                  <EventsCollapsible />
                </li>
              ) : null}

              {deployedUSA() ? (
                <li className="nav-item start ">
                  <NavLink to="/pages/support" className="nav-link" id="sidebar-meetings">
                    <i aria-hidden className="icon-call-out" />
                    <span className="title">
                      <FormattedMessage id="support.sidebar.title" />
                    </span>
                  </NavLink>
                </li>
              ) : null}

              <li className="nav-item start ">
                <NavLink to="/pages/meetings" className="nav-link" id="sidebar-meetings">
                  <i aria-hidden className="icon-calendar" />
                  <span className="title">
                    <FormattedMessage id="meetings.title" />
                  </span>
                </NavLink>
              </li>

              <li className="nav-item start ">
                <NavLink to="/pages/manuals" className="nav-link" id="sidebar-instructions">
                  <i aria-hidden className="icon-notebook" />
                  <span className="title">
                    <FormattedMessage id="manuals.title" />
                  </span>
                </NavLink>
              </li>

              {intl.locale == "ru" ? (
                <li className="nav-item start ">
                  <a
                    href="https://smile-crm-object-storage.storage.yandexcloud.net/application/public/static/promotion/3DSmileCases.pdf"
                    className="nav-link"
                    rel="noopener noreferrer"
                    target="_blank"
                    id="sidebar-calendar"
                  >
                    <i aria-hidden className="icon-doc" />
                    <span className="title">
                      <FormattedMessage id="left.menu.library" />
                    </span>
                  </a>
                </li>
              ) : null}
            </ul>
          </li>
        </ul>

        {!isMobile &&
        bannersLeft.length > 0 &&
        (deployedUSA() || (deployedRussia() && (window.location.hostname.endsWith(".ru") || isDevelopment))) ? (
          <RenderBanners banners={bannersLeft} />
        ) : null}
      </aside>
    </div>
  );
}

// FIXME: because of the framer motion animation, aria-expanded attribute is set incorrectly.
// When the menu is open, it is set to false.
function EventsCollapsible() {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const isEventsPage = location.pathname.startsWith("/pages/events");

  const unpaidEventsQuery = useGetUnpaidEventsQuery();
  const numberOfUnpaidEvents = unpaidEventsQuery.isSuccess
    ? Object.values(unpaidEventsQuery.data).reduce((a, b) => a + b, 0)
    : 0;

  const isMenuOpen = (isOpen && !isEventsPage) || (!isOpen && isEventsPage);

  function closePatientMenuOnSmallScreens() {
    if (window.innerWidth <= 992) {
      const patientMenuToggleBtn = document.getElementById("patient-menu-toggle-btn");
      invariant(patientMenuToggleBtn, "expected #patient-menu-toggle-btn but got undefined");
      patientMenuToggleBtn.click();
    }
  }

  const shouldReduceMotion = useReducedMotion();
  const animationProps: AnimationProps = {
    initial: { height: 0, opacity: 0 },
    animate: {
      height: "auto",
      opacity: 1,
      transition: {
        height: { duration: 0.25 },
        opacity: { duration: 0.1, delay: 0.05 },
      },
    },
    exit: {
      height: 0,
      opacity: 0,
      transition: {
        height: { duration: 0.25 },
        opacity: { duration: 0.1 },
      },
    },
  };

  return (
    <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
      <Collapsible.Trigger
        id="sidebar-meetings"
        className={clsx("nav-link", styles.menuButton, "tw-inline-flex tw-gap-1")}
      >
        <AcademicCapIcon width={20} height={20} className={styles.iconGlobe} />
        <FormattedMessage id="events" />
        <ChevronDownIcon
          className={clsx(
            "tw-h-5 tw-w-5 tw-ease-in-out motion-safe:tw-transition motion-safe:tw-duration-250",
            { "tw-rotate-180": isMenuOpen },
          )}
        />

        {!isMenuOpen && numberOfUnpaidEvents > 0 ? (
          <Badge className="tw-ml-auto" srOnlyText={intl.formatMessage({ id: "events.badge" })}>
            {numberOfUnpaidEvents}
          </Badge>
        ) : null}
      </Collapsible.Trigger>

      <AnimatePresence initial={false}>
        {isMenuOpen ? (
          <Collapsible.Content asChild forceMount>
            <motion.div {...(shouldReduceMotion == true ? {} : animationProps)}>
              <NavLink
                exact
                to="/pages/events"
                className={clsx(
                  "nav-link",
                  styles.menuItem,
                  "tw-mt-0.5 tw-inline-flex tw-gap-2",
                  "tw-no-underline tw-ring-blue-600 focus-visible:tw-ring-2",
                  numberOfUnpaidEvents < 10 ? "tw-pl-7" : "tw-pl-5",
                )}
                activeClassName={styles.menuItemActive}
                onClick={closePatientMenuOnSmallScreens}
              >
                <FormattedMessage id="events.upcoming" />
              </NavLink>

              <NavLink
                exact
                to="/pages/events/enrolled"
                className={clsx(
                  "nav-link",
                  styles.menuItem,
                  "tw-mt-0.5 tw-inline-flex tw-gap-2",
                  "tw-no-underline tw-ring-blue-600 focus-visible:tw-ring-2",
                  numberOfUnpaidEvents < 10 ? "tw-pl-7" : "tw-pl-5",
                )}
                activeClassName={styles.menuItemActive}
                onClick={closePatientMenuOnSmallScreens}
              >
                <span className="tw-relative">
                  <FormattedMessage id="events.enrolled" />

                  {isMenuOpen && numberOfUnpaidEvents > 0 ? (
                    <Badge
                      className="tw-absolute -tw-right-5 -tw-top-4"
                      srOnlyText={intl.formatMessage({ id: "events.badge" })}
                    >
                      {numberOfUnpaidEvents}
                    </Badge>
                  ) : null}
                </span>
              </NavLink>
            </motion.div>
          </Collapsible.Content>
        ) : null}
      </AnimatePresence>
    </Collapsible.Root>
  );
}
