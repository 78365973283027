import React, { Component } from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { DentalNotationType,PaymentMethodId } from '../../common/constants';
import remoteLog from '../../common/logging';
import { deployedRussia, deployedUSA } from '../../common/utils';
import { setDocumentTitle } from '../../hooks/use-document-title';
import type { RootState } from '../../store';
import Loader from '../common/loadingInProgress';
import { PersonName } from '../common/PersonName';
import { Layout } from '../ui/layout';

const mapStateToProps = (state: RootState) => {
    return {
        user: state.user,
        clinic: state.clinic,
    };
};

type DoctorPageProps = PropsFromRedux & { intl: IntlShape };

class DoctorPage extends Component<DoctorPageProps> {
    componentDidCatch(e: Error) {
        remoteLog(e, 'doctor_page');
    }

    componentDidMount() {
        const { user } = this.props;

        _paq.push(['HeatmapSessionRecording::enable']);
        if (user && user.account_id) {
            _paq.push(['setUserId', user.account_id.toString()]);
        }

        setDocumentTitle(this.props.intl.formatMessage({ id: "top.menu.profile" }));
    }

    enabledNotifications() {
        const notification_list = []
        if (deployedRussia() && this.props.user.telegram_notify && this.props.user.telegram_notify != "None") {
            notification_list.push('Telegram')
        }
        if (this.props.user.email_notify && this.props.user.email_notify != "None") {
            notification_list.push('Email')
        }

        if (notification_list.length) {
            return notification_list.join(', ')
        } else {
            return <FormattedMessage id="not.selected" />
        }
    }

    getPaymentMethodTranslation(default_payment_method) {
        let translation_id = 'not.selected';
        if (default_payment_method === PaymentMethodId.CARD) {
            translation_id = "PM_CARD.usa";
        } else if (default_payment_method === PaymentMethodId.INVOICE) {
            translation_id = "PM_INVOICE";
        } else if (default_payment_method === PaymentMethodId.CASH) {
            translation_id = "PM_CASH";
        }
        return translation_id;
    }

    renderDentalNotation() {
        const { preferences } = this.props.user;
        let dentalNotation = "DENTAL_NOTATION_TYPE_FDI";
        if (preferences.dental_notation === DentalNotationType.FDI) {
            dentalNotation = "DENTAL_NOTATION_TYPE_FDI";
        } else if (preferences.dental_notation === DentalNotationType.PALMER) {
            dentalNotation = "DENTAL_NOTATION_TYPE_PALMER";
        } else if (preferences.dental_notation === DentalNotationType.UNIVERSAL) {
            dentalNotation = "DENTAL_NOTATION_TYPE_UNIVERSAL";
        }
        return (
            <div>
                <label className="control-label"><strong><FormattedMessage
                    id="DENTAL_NOTATION_HEADER" /></strong></label>
                <p><FormattedMessage id={dentalNotation} /></p>
            </div>
        )
    }

    renderPreferences() {
        const { user } = this.props;
        const { preferences } = user;

        return (
            <div className="form-body">
                <h3><FormattedMessage id="preferences" /></h3>

                {deployedUSA() ? this.renderDentalNotation() : null}

                <h4 style={{ marginTop: 32 }}><b><FormattedMessage id="preferences.ipr" /></b></h4>
                <div className="row">
                    <div className="col-md-4">
                        {preferences.perform_ipr == null ? <p><FormattedMessage id="None" /></p> :
                            <div className="form-group">
                                <label className="control-label"><b><FormattedMessage id="preferences.ipr_enabled" /></b></label>
                                <p><FormattedMessage id={`bool.${preferences.perform_ipr}`} /></p>
                            </div>
                        }
                    </div>
                    <div className="col-md-4">
                        {!preferences.perform_ipr
                            || preferences.ipr_start_step == null
                            ? null :
                            <div className="form-group">
                                <label className="control-label">
                                    <b><FormattedMessage id="preferences.ipr_start_step" /></b>
                                </label>
                                <p>{preferences.ipr_start_step}</p>
                            </div>
                        }
                    </div>
                </div>

                <h4 style={{ marginTop: 32 }}><b><FormattedMessage id="preferences.attachments" /></b></h4>
                {preferences.place_attachments_on_step == null ? null :
                    <div className="form-group">
                        <label className="control-label">
                            <b><FormattedMessage id="preferences.attachments_delay" /></b>
                        </label>
                        <p>{preferences.place_attachments_on_step}</p>
                    </div>
                }

                <h4 style={{ marginTop: 32 }}><b><FormattedMessage id="preferences.expansion" /></b></h4>
                {preferences.always_expand == null ? <p><FormattedMessage id="None" /></p> :
                    <div className="form-group">
                        <label className="control-label">
                            <b><FormattedMessage id="preferences.always_expand" /></b>
                        </label>
                        <p><FormattedMessage id={`bool.${preferences.always_expand}`} /></p>
                    </div>
                }
                {(deployedUSA() && user.specialty_id === 1) || deployedRussia() ? (
                    <div>
                        <h4 style={{ marginTop: 32 }}>
                        <b><FormattedMessage id="preferences.incisor_relationship" /></b>
                        </h4>
                        <div className="row">
                            <div className="col-md-4">
                                {preferences.incisor_relationship_overjet == null ? null :
                                    <div className="form-group">
                                        <label className="control-label">
                                            <b><FormattedMessage id="preferences.incisor_relationship.overjet" /></b>
                                        </label>
                                            <p>{preferences.incisor_relationship_overjet}</p>
                                     </div>
                                }
                            </div>
                            <div className="col-md-4">
                                {preferences.incisor_relationship_overbite == null ? null :
                                    <div className="form-group">
                                        <label className="control-label">
                                            <b><FormattedMessage id="preferences.incisor_relationship.overbite" /></b></label>
                                        <p>{preferences.incisor_relationship_overbite}</p>
                                    </div>
                                }
                            </div>
                            <div className="col-md-4">
                                {preferences.upper_incisor_angulation == null ? null :
                                    <div className="form-group">
                                        <label className="control-label">
                                            <b><FormattedMessage id="preferences.upper_incisor_angulation" /></b></label>
                                        <p>{preferences.upper_incisor_angulation}</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <h4 style={{ marginTop: 32 }}>
                        <b><FormattedMessage id="preferences.overcorrection" /></b>
                        </h4>
                        <div className="row">
                            <div className="col-md-4">
                                {preferences.deep_bite_intrusion == null
                                    ? (
                                        <div className="form-group">
                                            <label className="control-label"><b><FormattedMessage id="preferences.deep_bite_intrusion" /></b></label>
                                             <p><FormattedMessage id="None" /></p>
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label className="control-label"><b><FormattedMessage id="preferences.deep_bite_intrusion" /></b></label>
                                            <p><FormattedMessage id={`bool.${preferences.deep_bite_intrusion}`} /></p>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-md-4">
                                {preferences.incisors_extrusion == null
                                    ? (
                                        <div className="form-group">
                                            <label className="control-label"><b><FormattedMessage id="preferences.incisors_extrusion" /></b></label>
                                            <p><FormattedMessage id="None" /></p>
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label className="control-label"><b><FormattedMessage id="preferences.incisors_extrusion" /></b></label>
                                            <p><FormattedMessage id={`bool.${preferences.incisors_extrusion}`} /></p>
                                        </div>
                                    )
                                }
                            </div>
                             <div className="col-md-4">
                                {preferences.rotation_over_30dg == null
                                    ? (
                                        <div className="form-group">
                                            <label className="control-label"><b><FormattedMessage id="preferences.rotation_over_30dg" /></b></label>
                                            <p><FormattedMessage id="None" /></p>
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label className="control-label"><b><FormattedMessage id="preferences.rotation_over_30dg" /></b></label>
                                            <p><FormattedMessage id={`bool.${preferences.rotation_over_30dg}`} /></p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ) : null
                }

                <h4 style={{ marginTop: 32 }}><b><FormattedMessage id="preferences.additional_notes" /></b></h4>
                {preferences.special_instructions == null ? null :
                    <div className="form-group">
                        {/* <label className="control-label"><b><FormattedMessage id="preferences.additional_notes"/></b></label> */}
                        <p>{preferences.special_instructions}</p>
                    </div>
                }
            </div>
        )
    }

    render() {
        const { user } = this.props;
        const { preferences } = user;

        if (!user.login) {
            return (
                <Layout>
                    <Loader />
                </Layout>
            );
        }

        const payment_method_id = this.getPaymentMethodTranslation(preferences.default_payment_method);

        return (
              <Layout>
                    <div className="portlet light bordered">
                        <div className="portlet-title">
                            <div className="caption">
                                <i className="icon-user font-green" />
                                <span className="caption-subject font-green bold uppercase">
                                    <FormattedMessage id="top.menu.profile" />
                                </span>
                            </div>
                            <div style={{float: "right"}} className="form-actions doctor-actions-btns">
                                <NavLink to="/pages/edit_my_account">
                                    <button className="btn green"><FormattedMessage id="BTN_EDIT" /></button>
                                </NavLink>
                            </div>
                        </div>
                        <div className="portlet-body form">
                            <h3><FormattedMessage id="doctor.profile.info" /></h3>
                            <div className="form-body">
                                <label className="control-label">
                                    <strong><FormattedMessage id="login.login" /></strong>
                                </label>
                                <p>{user.login}</p>

                                <label className="control-label">
                                    <strong><FormattedMessage id="doctor.fullname" /></strong>
                                </label>
                                <p><PersonName person={user} isDoctor useMiddleName /></p>

                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="control-label">
                                            <strong> Email </strong>
                                        </label>
                                        <p>{user.email}</p>
                                    </div>

                                    {user.phone && user.phone != "None"
                                        ? (
                                            <div className="col-md-4">
                                                <label className="control-label">
                                                    <strong><FormattedMessage id="clin_table.phone" /></strong>
                                                </label>
                                                <p>{formatPhoneNumber(user.phone)}</p>
                                            </div>
                                        ) : null
                                    }

                                    {deployedRussia() === true && user.telegram_id && user.telegram_id != "None"
                                        ? (
                                            <div className="col-md-4">
                                                <label className="control-label"><strong> Telegram ID </strong></label>
                                                <p>{user.telegram_id}</p>
                                            </div>
                                        ) : null
                                    }
                                </div>

                                <label className="control-label">
                                    <strong><FormattedMessage id="notify.doctor.subscribe" /></strong>
                                </label>
                                <p>{this.enabledNotifications()}</p>

                                {deployedUSA() ?
                                    (
                                        <div>
                                            <label className="control-label">
                                                <strong><FormattedMessage id="HEADER_PAYMENT_METHOD" /></strong>
                                            </label>
                                            <div className="form-group">
                                                <p><FormattedMessage id={payment_method_id} /></p>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>

                            <hr />
                            {this.renderPreferences()}
                        </div>
                        <div className="form-actions doctor-actions-btns">
                            <NavLink to="/pages/edit_my_account">
                                <button className="btn green"><FormattedMessage id="BTN_EDIT" /></button>
                            </NavLink>
                            {deployedUSA() && location.protocol === 'https:'
                                ? (
                                    <NavLink to="/pages/doctor/payment/account">
                                        <button className="btn green"><FormattedMessage id="PAYMENT_DATA" /></button>
                                    </NavLink>
                                ) : null
                            }
                        </div>
                    </div>
              </Layout>
        )
    }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(DoctorPage));

function formatPhoneNumber(phone: string): string {
  if (/^\+7\d{10}$/.test(phone)) {
    const first = phone.slice(2, 5);
    const second = phone.slice(5, 8);
    const third = phone.slice(8, 10);
    const fourth = phone.slice(10);
    return `+7 (${first}) ${second}-${third}-${fourth}`;
  }

  if (/^8\d{10}$/.test(phone)) {
    const first = phone.slice(1, 4);
    const second = phone.slice(4, 7);
    const third = phone.slice(7, 9);
    const fourth = phone.slice(9);
    return `8 (${first}) ${second}-${third}-${fourth}`;
  }

  return phone;
}
