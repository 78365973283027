import clsx from "clsx";
import { AnimatePresence, type AnimationProps, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import remoteLog from "~/common/logging";
import { useOptionalUser } from "~/common/user";
import { Button } from "~/components/ui/button";
import { Layout } from "~/components/ui/layout";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { useDocumentTitle } from "~/hooks/use-document-title";

const animationProps: AnimationProps = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.4 } },
  exit: { opacity: 0, transition: { duration: 0.15 } },
};

export function YandexMetrikaPopUp() {
  const user = useOptionalUser();
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!user || user.privileges.accepted_yandex_metrika) {
      return;
    }
    const timeout = window.setTimeout(() => setIsVisible(true), 750);
    return () => window.clearTimeout(timeout);
  }, [user]);

  if (!user || user.privileges.accepted_yandex_metrika) {
    return null;
  }

  async function handleAcceptClick() {
    if (isSubmitting) {
      return;
    }
    try {
      setIsSubmitting(true);
      const response = await fetch(`${process.env.API}/yandex-metrika`, {
        method: "POST",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(
          `Error occurred trying to accept yandex metrika "${response.status} ${response.statusText}"`,
        );
      }
    } catch (err) {
      remoteLog(err, "yandex_metrika_pop_up");
    } finally {
      setIsVisible(false);
      setIsSubmitting(false);
    }
  }

  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          {...animationProps}
          className={clsx(
            "tw-fixed tw-bottom-2 tw-z-50 tw-rounded-xl tw-bg-white tw-p-6 tw-shadow-lg",
            "tw-border tw-border-solid tw-border-gray-300",
            "max-sm:tw-inset-x-1 sm:tw-bottom-6 sm:tw-right-6",
          )}
        >
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-leading-8">
            <p className="tw-m-0">
              Мы используем файлы cookie для улучшения работы сайта.
              <span className="tw-block tw-h-1" />
              <Link to="/pages/yandex-metrika" className="tw-underline tw-underline-offset-4">
                Подробнее
              </Link>
            </p>

            <Button rounded onClick={handleAcceptClick} className="max-sm:tw-w-28">
              OK
            </Button>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

export default function YandexMetrikaPage() {
  useDocumentTitle("Яндекс Метрика");

  return (
    <Layout contentClassName="max-sm:-tw-mx-2 max-sm:-tw-my-6">
      <Portlet as="main">
        <PortletTitle iconClassName="icon-info">Яндекс Метрика</PortletTitle>

        <div className="portlet-body">
          <p>
            Этот сайт использует сервис веб-аналитики Яндекс Метрика, предоставляемый компанией ООО
            «ЯНДЕКС», 119021, Россия, Москва, ул. Л. Толстого, 16 (далее — Яндекс).
          </p>

          <p>
            Сервис Яндекс Метрика использует технологию «cookie» — небольшие текстовые файлы,
            размещаемые на компьютере пользователей с целью анализа их пользовательской активности.
          </p>

          <p>
            Собранная при помощи cookie информация не может идентифицировать вас, однако может
            помочь нам улучшить работу нашего сайта. Информация об использовании вами данного сайта,
            собранная при помощи cookie, будет передаваться Яндексу и храниться на сервере Яндекса в
            РФ и/или в ЕЭЗ. Яндекс будет обрабатывать эту информацию в интересах владельца сайта, в
            частности для оценки использования вами сайта, составления отчетов об активности на
            сайте. Яндекс обрабатывает эту информацию в порядке, установленном в Условиях
            использования сервиса Яндекс Метрика.
          </p>

          <p>
            Вы можете отказаться от использования cookies, выбрав соответствующие настройки в
            браузере. Также вы можете использовать инструмент —
            <a
              href="https://yandex.ru/support/metrika/general/opt-out.html"
              target="_black"
              rel="noopener noreferrer"
            >
              https://yandex.ru/support/metrika/general/opt-out.html
            </a>
            . Однако это может повлиять на работу некоторых функций сайта. Используя этот сайт, вы
            соглашаетесь на обработку данных о вас в порядке и целях, указанных выше.
          </p>
        </div>
      </Portlet>
    </Layout>
  );
}
